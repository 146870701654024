import React, { useEffect, useState } from 'react';
import { Form,Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { PropagateLoader } from 'react-spinners';
import { useTranslate } from '../LanguageProvider';
import { toTimestamp } from '../../utilities/functions';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Définition de l'interface pour les données de rapport
interface ReportData {
  id_repport: number;
  date_debut: string;
  date_fin: string;
  temps_arret: string;
  temps_marche: string;
  temps_ralenti: string;
  first_contact: string;
  last_contact: string;
  amplitude_travail: string;
  temps_privé: string;
  psn: string;
  distance: number;
  acc: number;
  brk: number;
  max_speed: number;
  avg_speed: number;
  nbr_arrets: number;
  nbr_ralenti: number;
  distance_privé: number;
  distance_can: number;
  total_carburant: number;
  niv_carburant: number;
  avg_carburant: number;
  duration: string;
  start: string;
  end: string;
  score: number;
  rest_time: number;
  driving_time: number;
}


interface Report31Props {
  type_report?: string;
  turn?: string;
  id_report?: string;
 
}

const Report31: React.FC<Report31Props> = ({ type_report, turn, id_report}) => {
 
  const { translate } = useTranslate();
  const [pageCount, setpageCount] = useState(0);
  let [total, settotal] = useState(0);
  let [limitValue, setSelectedlimitValue] = useState(10);
  const [loading, setLoading] = useState(true); // Add loading state
  const [showDropdown, setShowDropdown] = useState<string>("");
  const [inputAdvenceSearch, setinputAdvenceSearch] = useState<string>("Vehicle");
  const [inputTypeSearch, setInputTypeSearch] = useState<number>(0);
  const [list_report, setItems] = useState<ReportData[]>([]);



  let currentPage = 1;

  interface MenuItem {
    id: number;
    fieldName: string;
    label: string;
  }

  const MenuItems: MenuItem[] = [
    { id: 0, fieldName: translate('Vehicle'), label: translate('Vehicle') },
    { id: 1, fieldName: translate('Group'), label: translate('Vehicles group') },
    { id: 2, fieldName: 'PSN', label: 'PSN' },
    { id: 3, fieldName: translate('User'), label: translate('User') },
  ];


  const handleAdvenceClick = () => {

    console.log('Clicked on list:');

    showDropdown == "" ? setShowDropdown("show-dropdown") : setShowDropdown("");


  };
  const searchBy = async (search: string, type: number) => {

    console.log(search === undefined);
    try {
      setLoading(true);
      const total_pages = await fetch(
        search.trim() !== ""
          ? `${backendUrl}/api/vehicle/search/totalpage/${userID}/${search}/${type}`
          : `${backendUrl}/api/vehicle/totalpage/${userID}`,
        { mode: 'cors' }
      );
      const totalpages = await total_pages.json();
      total = totalpages[0]["total"];
      settotal(totalpages[0]["total"]);

      const res = await fetch(
        search.trim() !== ""
          ? `${backendUrl}/api/vehicle/search/1/${limitValue}/${userID}/${search}/${type}`
          : `${backendUrl}/api/vehicle/1/${limitValue}/${userID}`,
        { mode: 'cors' }
      );

      const data = await res.json();
      setpageCount(Math.ceil(total / limitValue));
      setItems(data);
    } catch (error) {
      console.error(error);

    } finally {
      setLoading(false);
    }
  };


  const handlePageClick = async (data: any) => {
    currentPage = data.selected + 1;
    const commentsFormServer = await fetchReport(currentPage, limitValue);

    setItems(commentsFormServer);
    window.scrollTo(0, 0)
  };

  const handleItemClick = (fieldId: number, fieldName: string) => {

    console.log('Clicked on:', fieldName);

    setinputAdvenceSearch(fieldName);
    setInputTypeSearch(fieldId);
    setShowDropdown("");

  };


  // Function to handle the change in the select element
  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setSelectedlimitValue(newValue);
    const commentsFormServer = await fetchReport(currentPage, newValue);

    setItems(commentsFormServer);

  };

  const userID = localStorage.getItem("userID");

  const fetchReport = async (currentPage: any, limitValue: any) => {

    const response = await fetch(`${backendUrl}/api/Report31/totalpage/${id_report}`);
    const res = await fetch(

      `${backendUrl}/api/Report31/${id_report}/${currentPage}/${limitValue}`,

      { mode: 'cors' }
    );
    const data = await res.json();
    return data;
  };



  useEffect(() => {



    const getReport = async () => {
      try {
        setLoading(true);
        const total_pages = await fetch(
          `${backendUrl}/api/Report31/totalpage/${id_report}`,
          { mode: 'cors' }
        );
        const totalpages = await total_pages.json();
        total = totalpages[0]["total"];
        settotal(totalpages[0]["total"]);

        const res = await fetch(
          `${backendUrl}/api/Report31/${id_report}/1/${limitValue}`,
          { mode: 'cors' }
        );

        const data = await res.json();
        setpageCount(Math.ceil(total / limitValue));
        setItems(data);
      } catch (error) {
        console.error(error);

      } finally {
        setLoading(false);
      }
    };

    getReport();
  }, [userID, limitValue]);


  return (
    <>
      <div className="row">
        <h6 className="col-sm-6 col-md-6">
          <i className="las la-users-cog" data-rel="bootstrap-tooltip" title="Increased"></i> {translate('Fleet Report ')}
          <span style={{ color: '#3468C0' }}>{list_report.length > 0 ? toTimestamp(list_report[0].date_debut) : ''}</span> au{' '}
          <span style={{ color: '#3468C0' }}>{list_report.length > 0 ? toTimestamp(list_report[0].date_fin) : ''}</span>
        </h6>
        <div className="col-sm-6 col-md-6 " >
          <div id="DataTables_Table_0_filter" className="float-right dataTables_filter mr-3">
            <a href="#" className="btn btn-outline-info ">
              <i className="las la-file-excel mr-3"></i>
              {translate('Download')} Excel
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="row m-2">
          <div className="col-sm-6 col-md-6" style={{ margin: "0px 0px 10px 0px", padding: "0px" }}>

            <Form className="d-flex">

              <div className="dropdown">
                <button
                  className="btn btn-default dropdown-toggle"
                  type="button"
                  name="dropdown_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => handleAdvenceClick()}
                >
                  <span className="las la-chevron-down" data-toggle="tooltip" title="Search by vehicle"></span>
                </button>
                <ul style={{ padding: "4px" }} className={`dropdown-menu  ${showDropdown}`} role="menu" id="search_by">
                  {MenuItems.map((item) => (
                    <li key={item.id} className={item.id === 1 ? 'active' : ''}>
                      <a
                        onClick={() => handleItemClick(item.id, item.fieldName)}
                        style={{ cursor: 'pointer' }}
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <Form.Control
                type="search"
                placeholder={`${translate('Search by')} ${translate(inputAdvenceSearch)}`}
                className="me-2"
                aria-label={`${translate('Search by')} ${translate(inputAdvenceSearch)}`}
                onChange={(e) => searchBy(e.target.value, inputTypeSearch)}
              />
            </Form>

          </div>
          <div className="col-6 col-md-6">
            <label style={{ float: "right" }}>
              {translate('Show')}
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm mr-2 ml-2"
                style={{ width: "66px" }}
                onChange={handleSelectChange}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="90">90</option>
                <option value="180">180</option>
                <option value="300">300</option>
                <option value="600">600</option>
                <option value="900">900</option>
                <option value="2000">2000</option>
              </select>
              {translate('entries')}
            </label>
          </div>
        </div>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>{translate('Vehicle')}</th>
              <th>{translate('Stop time')}</th>
              <th>{translate('Driving time')}</th>
              <th>{translate('Slowed driving time')}</th>
              <th>{translate('Number of stops')}</th>
              <th>{translate('Number of slowed')}</th>
              <th>{translate('First contact')}</th>
              <th>{translate('Last contact')}</th>
              <th>{translate('Work amplitude')}</th>
              <th>{translate('Private mode time')}</th>
              <th>{translate('Private mode distance')}(km)</th>
              <th>{translate('Distance')}(km)</th>
              <th>{translate('Distance')}CAN (km)</th>
              <th>{translate('Maximum speed')}(km/h)</th>
              <th>{translate('Average speed')}(km/h)</th>
              <th >{translate('Fuel consumption')}(L)</th>
              <th >{translate('Average fuel consumption')}(L/100km)</th>
              <th >{translate('Fuel filled')}(%)</th>
            </tr>
          </thead>
          <tbody>
            {list_report.length !== 0 && list_report.map((data: ReportData) => {

            
              return (
                <tr key={data.id_repport} role="row" >
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.psn}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.temps_arret}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.temps_marche}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.temps_ralenti}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.nbr_arrets}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.nbr_ralenti}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{toTimestamp(data.first_contact)}</td> 
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{toTimestamp(data.last_contact)}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.amplitude_travail}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.temps_privé}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.distance_privé}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.distance}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.distance_can}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.max_speed}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{Math.round(data.avg_speed)}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.total_carburant}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.avg_carburant}</td>
                  <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{data.niv_carburant}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {loading ? (
        <div className="text-center">
          <PropagateLoader color={'#123abc'} loading={loading} size={20} />
        </div>
      ) : (
        <>
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </>
      )}

    </>

  );
};

export default Report31;
