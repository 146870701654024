// Popover.tsx
import React from 'react';
import { OverlayTrigger, Popover as BootstrapPopover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

interface PopoverProps {
  title: string;
  content: string;
  icon: string;
  colorLink: string;
  id: string;
}

const PopoverComponent: React.FC<PopoverProps> = ({ title, content, icon, colorLink, id }) => {
  const popover = (
    <BootstrapPopover id={`popover-${id}`}>
      <BootstrapPopover.Header as="h3">{title}</BootstrapPopover.Header>
      <BootstrapPopover.Body>
        {content}
      </BootstrapPopover.Body>
    </BootstrapPopover>
  );

  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <a className={colorLink} style={{cursor: "pointer"}}> 
        <i className={`fa fa-${icon}`}></i> 
      </a>
    </OverlayTrigger>
  );
}

export default PopoverComponent;
