import React, { useState, useEffect } from 'react';

interface SpeedAlarmProps {
  alarm: any; // Adapter le type en fonction de votre structure d'alarme
  onChange: (newMetrics: any) => void; // Fonction de rappel pour mettre à jour l'état du composant parent
}

const SpeedAlarm: React.FC<SpeedAlarmProps> = ({ alarm, onChange }) => {
  const [speed, setSpeed] = useState<string>("80");
   
  useEffect(() => {  
    console.log("alarm", alarm);
    if(alarm !== ""){  
      setSpeed(alarm.max_speed);
    } else { 
      setSpeed("80");
    }
  }, [alarm]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log(value); 
    setSpeed(value);
    console.log(alarm);      
    onChange({ type:"SpeedAlarm", max_speed: value });  
  };

  return (
    <div className="input-group col-md-4 col-sm-4">
      <input
        className="form-control input-sm" 
        name="Speeding"
        value={speed}
        type="text"
        placeholder='Speed'
        size={10}
        maxLength={20}
        onChange={handleInputChange}
      />
      <span style={{paddingLeft:10}} className="input-group-addon">Km/h</span>
    </div>
  );
};

export default SpeedAlarm;
