import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal, Nav, Table } from "react-bootstrap";
import { Form, Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../components/LanguageProvider";
import { formatToTimestamp } from "../utilities/functions";
import { PropagateLoader } from "react-spinners";
import AlarmGrouptModal from "../components/Alarm/Modals/GroupAlarm";
import Popover from "../components/Alarm/Popover";
import PopoverComponent from "../components/Alarm/Popover";


interface Alarms {
  id_alarme: number;
  name_alarme: string;
  id_type_alarme: number;
  type_alarme: string;
  groupe_alarme: true;
  username_user: string;
  id_groupe_alarme: number;
  date_creation: string;
  id_user: number;
  seuil: string;
  val_seuil: string;
}

export function Alarms() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { translate } = useTranslate();
  let [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list_Alarms, setAlarms] = useState<Alarms[]>([]);
  const id_user = localStorage.getItem("userID");
  const [showCreateAlarmtModal, setShowCreateAlarmtModal] = useState(false);
  const handleShowCreateAlarmtModal = () => setShowCreateAlarmtModal(true);
  const handleCloseCreateAlarmtModal = () => setShowCreateAlarmtModal(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [pageCount, setpageCount] = useState(0);
  let [total, settotal] = useState(0);
  const [colum, setSortColum] = useState("id_alarme");
  const [sort, setSort] = useState("ASC");
  const [search, setSearch] = useState("");
  const [type, setType] = useState(0);
  const [typeSeach, setTypeSeach] = useState("Alarm ID");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 

  const getAlarm = async (
    limitValue: number,
    currentPage: number,
    search: string,
    type: number,
    colum: string,
    sortr: string
  ) => {
    try {
      setLoading(true);

      // Préparation des données à envoyer
      const bodyData = JSON.stringify({
        limitValue,
        currentPage,
        search,
        type,
        id_user,
        colum,
        sort,
      });

      // Récupération du nombre total de pages
      const totalPagesResponse = await fetch(
        `${backendUrl}/api/alarm/totalpage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const totalPagesJson = await totalPagesResponse.json();
      const total = totalPagesJson[0]["count"];
      settotal(total);

      // Récupération des données d'alarmes
      const alarmsResponse = await fetch(`${backendUrl}/api/alarm/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      const data = await alarmsResponse.json();
      setpageCount(Math.ceil(total / limitValue));
      setLimit(limitValue);
      setAlarms(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const refreshAlarms = () => {
    getAlarm(limit, currentPage, search, type, colum, sort);
  };


  const getAlarmlimitValue = async (
    limitValue: number,
    currentPage: number,
    search: string,
    type: number,
    colum: string,
    sortr: string
  ) => {
    try {
      setLoading(true);

      // Préparation des données à envoyer
      const bodyData = JSON.stringify({
        limitValue,
        currentPage,
        search,
        type,
        id_user,
        colum,
        sort,
      });

      // Récupération du nombre total de pages
      const totalPagesResponse = await fetch(
        `${backendUrl}/api/alarm/totalpage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodyData,
          mode: "cors",
        }
      );

      const totalPagesJson = await totalPagesResponse.json();
      const total = totalPagesJson[0]["count"];
      settotal(total);

      // Récupération des données d'alarmes
      const alarmsResponse = await fetch(`${backendUrl}/api/alarm/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });

      const data = await alarmsResponse.json();
      setpageCount(Math.ceil(total / limitValue));
      setLimit(limitValue);

      return data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAlarm(
      limit,
      currentPage,
      search,
      type,
      colum,
      sort
    );
    // setAlarms(commentsFormServer);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getAlarm(limit, currentPage, search, type, colum, sort);
  }, []);

  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setCurrentPage(1); // Réinitialiser currentPage à 1 lorsque la limite change
    setLimit(newValue);
    const commentsFormServer = await getAlarmlimitValue(
      parseInt(newValue),
      1,
      search,
      type,
      colum,
      sort
    ); // Ajouter await ici
    setAlarms(commentsFormServer);
    window.scrollTo(0, 0);
  };

  const [selectedColumns, setSelectedColumns] = useState({
    id_alarme: true,
    name_alarme: true,
    id_type_alarme: true,
    type_alarme: true,
    groupe_alarme: true,
    id_groupe_alarme: true,
    date_creation: true,
    id_user: true,
    username_user: true,
    seuil: true,
    val_seuil: true,
  });

  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleTypeSearch = (event: any) => {
    const selectedValue = event.target.textContent;

    switch (selectedValue) {
      case translate("Alarm ID"):
        setType(0);
        break;
      case translate("Name"):
        setType(1);
        break;
      case translate("Type"):
        setType(2);
        break;
      case translate("User"):
        setType(3);
        break;
      default:
        console.log("Unknown selection");
        break;
    }

    setTypeSeach(selectedValue);
    console.log("Selected value:", selectedValue);
  };

  const handleAdvancedSearch = async (event: any) => {
    const newValue = event.target.value;
    setSearch(newValue);
    await getAlarm(limit, currentPage, newValue, type, colum, sort);
  };

  const handleSortingColum = (curentColum: string) => {
    setSortColum(curentColum);
    sort == "ASC" ? setSort("DESC") : setSort("ASC");
    setCurrentPage(1)
    getAlarm(limit, 1, search, type, colum, sort);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h4>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-bell"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
              </svg>
            </span>
            {translate("Alarms management")} ({total})
          </h4>
        </div>
        <div className="col-md-6 col-sm-12 text-right">



          <NavLink to="/alarm/add" className="btn btn-outline-info  mr-1">
            <i className="las la-plus mr-3"></i>
            {translate('Add alarm')}
          </NavLink >


          <NavLink to="/group-alarms" className="btn btn-primary  mr-1">
            <i className="las la-users-cog mr-3"></i>
            {translate('Alarm groups')}
          </NavLink >

          <a href="#" className="btn btn-outline-info ">
              <i className="las la-trash mr-3"></i> 
              {translate('Delete')} Alarm
          </a>
          
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-4"
          style={{ margin: "0px 0px 10px 0px", padding: "10px" }}
        >
          <div className="input-group">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i
                  className="fas fa-chevron-down"
                  style={{ fontSize: "20" }}
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu onClick={handleTypeSearch}>
                <Dropdown.Item>{translate("Alarm ID")}</Dropdown.Item>
                <Dropdown.Item>{translate("Name")}</Dropdown.Item>
                <Dropdown.Item>{translate("Type")}</Dropdown.Item>
                <Dropdown.Item>{translate("User")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <input
              type="text"
              placeholder={` By ${typeSeach}`}
              onChange={handleAdvancedSearch}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-8 d-flex justify-content-end align-items-center">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label style={{ marginBottom: "0" }}>
              {translate("Show")}
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                style={{ width: "66px" }}
                onChange={handleSelectChange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </label>
          </div>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              title="Colonnes dʼaffichage"
            >
              <i className="las la-eye"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.id_alarme}
                  onChange={() => handleColumnChange("id_alarme")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Alarm ID")}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.name_alarme}
                  onChange={() => handleColumnChange("name_alarme")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Name")}</span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.type_alarme}
                  onChange={() => handleColumnChange("type_alarme")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Type")}</span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.date_creation}
                  onChange={() => handleColumnChange("date_creation")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Date created ")}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.username_user}
                  onChange={() => handleColumnChange("username_user")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("User")}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="row m-1">
        <Table className="dataTable">
          <thead className="bg-white text-uppercase">
            <tr className="ligth ligth-data">
              <th>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label"></label>
                </div>
              </th>
              {selectedColumns.id_alarme && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("id_alarme")}
                >
                  {translate("Alarm ID")}
                </th>
              )}
              {selectedColumns.name_alarme && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("name_alarme")}
                >
                  {translate("Name")}
                </th>
              )}
              {selectedColumns.type_alarme && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("type_alarme")}
                >
                  {translate("Type")}
                </th>
              )}
              {selectedColumns.username_user && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("username_user")}
                >
                  {translate("User")}
                </th>
              )}
              {selectedColumns.date_creation && (
                <th
                  className="sorting"
                  onClick={() => handleSortingColum("date_creation")}
                >
                  {translate("Date created ")}
                </th>
              )}
              {<th>{translate("Parameters")}</th>}
            </tr>
          </thead>
          <tbody key="#" className="ligth-body">
            {loading ? (
              <tr>
                <td className="text-center" colSpan={7}>
                  <p>
                    <PropagateLoader
                      color={"#123abc"}
                      loading={loading}
                      size={20}
                    /></p>
                </td>
              </tr>
            ) : list_Alarms.length > 0 ? (
              list_Alarms.map((alarme) => (
                <tr key={alarme.id_alarme}>
                  <td>
                    <div className="form-check form-check-inline">
                      <input type="checkbox" className="form-check-input" />
                    </div>
                  </td>
                  {selectedColumns.id_alarme && <td>{alarme.id_alarme}</td>}
                  {selectedColumns.name_alarme && <td>{alarme.name_alarme}</td>}
                  {selectedColumns.type_alarme && <td>{alarme.type_alarme}</td>}
                  {selectedColumns.username_user && (<td>{alarme.username_user}</td>)}
                  {selectedColumns.date_creation && (<td>{formatToTimestamp(alarme.date_creation)}</td>)}
                  <td>
                    <div className="d-flex align-items-center list-action">

                      <Link
                        to={`/alarm/edit/${alarme.id_alarme}`}
                        className="badge badge-success mr-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Editer alarme"
                      >
                        <i className="las la-cog" style={{ fontSize: "1.2em" }}></i>
                      </Link>


                      <PopoverComponent
                        title="Méthodes de notification"
                        content={'Pop-up, Rapports'}
                        icon="exclamation-circle"
                        colorLink="badge bg-warning mr-2"
                        id={alarme.id_alarme.toString()}
                      />

                      <PopoverComponent
                        title="Attaché à"
                        content={'Caddy ORAN 05580-119-21 / 04BB0369 Dacia-Logane PORT AFIA.ORAN 03517-110-21 /  04BB05C5 DUSTER TAKI.ORAN 01387-111-21'}
                        icon="magnet"
                        colorLink="badge bg-primary"
                        id={alarme.id_alarme.toString()}
                      />

                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No alarm available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {list_Alarms.length} {translate("out of")}{" "}
            {total}
          </span>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
        <AlarmGrouptModal
          show={showCreateAlarmtModal}
          onHide={handleCloseCreateAlarmtModal}
          refreshAlarmGroupts={refreshAlarms}
          groupId={0} />
      </div>
    </>
  );
}
