import React, { useState, useEffect } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { useTranslate } from "../LanguageProvider";
import SelectReport from "../Vehicle/SelectReport";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

interface VehicleSelectionProps {
  fromDate: (FromDate: Date) => void;
  todDate: (TodDate: Date) => void;
  onVehicleSelect: (vehicles: { id: any; value: any }[]) => void;
}

const VehicleSelection: React.FC<VehicleSelectionProps> = ({
  fromDate,
  todDate,
  onVehicleSelect,
}) => {
  const { translate } = useTranslate();
  const [inputTypeSearchValue, setTypeSearchValue] = useState<number>(11);
  const [inputTypeSearchLabel, setTypeSearchLabel] = useState<string>( translate("Matriculation") );
  const [inputAdvenceSearch, setinputAdvenceSearch] = useState<string>("Vehicle");
  const [loading, setLoading] = useState(true);
  const id_user = localStorage.getItem("userID");
  const Midnight = new Date();
  const Now = new Date();
  const [startDate, setStartDate] = useState<Date>( new Date(Midnight.setHours(0, 0, 0, 0)));
  const [endDate, setEndDate] = useState<Date>(Now);
  const [suggestions, setSuggestions] = useState([]);
  const [itemsReports, setItemsReports] = useState<{ value: any; id: any }[]>( []);
  const [autocompleteState, setAutocompleteState] = useState("");
  let [itemSearch, setitemSearch] = useState("");
  const [initialSelectionDone, setInitialSelectionDone] = useState(false);
  const [SearchByIcon, setSearchByIcon] = useState("filter");
  const [SelectReportState, setSelectReportState] = useState(false);
  const [autocomplateState, setAutocomplateState] = useState("false");
  const [autocomplateStateIcon, setAutocomplateStateIcon] = useState("search");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);


  useEffect(() => {
    if (!initialSelectionDone) {
      onVehicleSelect(itemsReports);
      fromDate(startDate);
      todDate(endDate);
      setInitialSelectionDone(true);
    }
  }, []);

  const [searchTypeOptions, setsearchTypeOptions] = useState([
    { value: "11", label: "Matriculation" },
    { value: "12", label: "PSN" },
    { value: "14", label: "Vehicle group" },
    { value: "15", label: "User" },
  ]);

  const searchBy = async (option: any) => {
    setinputAdvenceSearch(translate(option.label));
    setTypeSearchLabel(translate(option.label));
    setTypeSearchValue(option.value);
    setItemsReports([]);
  };

  const getAutocomplete = async (search: string) => {
    setitemSearch(search);
    setAutocompleteState("");
    setAutocomplateStateIcon("spinner");
    
    try {
      if (search.trim() !== "") {
        const res = await fetch(
          `${backendUrl}/api/search/${inputTypeSearchValue}/${search}/${id_user}`,
          { mode: "cors" }
        );
        const data = await res.json();

        const getSuggestions = data.data.map((item: any) => {
          let newItem: any = {};

          if (inputTypeSearchValue == 11 || inputTypeSearchValue == 34) {
            newItem.value = item.immatriculation_vehicule;
            newItem.id = item.id_dispositif;
          } else if (inputTypeSearchValue == 12) {
            newItem.value = item.psn_dispositif;
            newItem.id = item.id_dispositif;
          } else if (inputTypeSearchValue == 14) {
            newItem.value = item.nom_groupe;
            newItem.id = item.id_groupe;
          } else if (inputTypeSearchValue == 15) {
            newItem.value = item.username_user;
            newItem.id = item.id_user;
          } else if (inputTypeSearchValue == 31 || inputTypeSearchValue == 51) {
            newItem.value = item.nom_conducteur + " " + item.prenom_conducteur;
            newItem.id = item.id_dispositif;
          } else if (inputTypeSearchValue == 33) {
            newItem.value = item.nom;
            newItem.id = item.id_agence;
          }

          return newItem;
        });

        setSuggestions(getSuggestions);
        setAutocomplateStateIcon("search");
      } else {
        setAutocomplateStateIcon("search");
        setSuggestions([]);
      }
    } catch (error) {
      setAutocomplateStateIcon("search");
      setSuggestions([]);
    }
  };

  const getAutocompleteFleet = async (search: string) => {
    setitemSearch("");
    setAutocompleteState("");
    try {
      if (search.trim() !== "") {
        const res = await fetch(
          `${backendUrl}/api/search/${21}/${"fleet"}/${id_user}`,
          { mode: "cors" }
        );
        const data = await res.json();

        const getSuggestions = data.data.map((item: any) => {
          let newItem: any = {};

          newItem.value = item.immatriculation_vehicule;
          newItem.id = item.id_dispositif;

          return newItem;
        });

        setItemsReports(getSuggestions);

        getSuggestions.forEach((item: { value: any }) => {
          updateCheckboxState(item.value);
        });

        setAutocompleteState("");
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setItems = async (newItem: any) => {
    // Vérifiez si l'élément existe déjà dans la liste
    const itemExists = itemsReports.some((item) => item.value === newItem.value);
   
    if (!itemExists) {
      // Si l'élément n'existe pas, ajoutez-le à la liste
      const updatedItemsReports = [...itemsReports, newItem];
      setItemsReports(updatedItemsReports);
      setAutocompleteState("autocomplete-state");
      setitemSearch(newItem.value);
      onVehicleSelect(updatedItemsReports);
      fromDate(startDate);
      todDate(endDate);
      updateCheckboxState(newItem);
    } else {
      setAutocompleteState("autocomplete-state");
      // Sinon, vous pouvez ajouter un comportement alternatif ici si nécessaire
      console.log("L'élément existe déjà dans la liste.");
    }
  }; 
  
  const addReportBy = async (type: string) => {
    try {
      setLoading(true);
      setSearchByIcon("spinner");
      setAutocomplateStateIcon("spinner");
      setSelectReportState(true);
      const res = await fetch(`${backendUrl}/api/search/report-for/${type}`, {
        mode: "cors",
      });
      const data = await res.json();
      setSearchByIcon("filter");
      setAutocomplateStateIcon("search");
      setSelectReportState(false);
      const optionsArray = data["data"][0]["searchfor"].split(",");
      setsearchTypeOptions(
        optionsArray.map((option: any, index: any) => ({
          value: type.toString() + (index + 1).toString(),
          label: translate(option.trim()),
        }))
      );

      if (type === "2") {
        setSelectReportState(true)
        console.log("Autocomple fleet: ");   
        setTypeSearchValue(parseInt(type.toString()+"1"));
        getAutocompleteFleet("fleet"+type); 
      } else {
        setSelectReportState(false)
        setTypeSearchValue(parseInt(type.toString()+"1"));
        console.log("Autocomple: "+type); 
      }

      setTypeSearchLabel(translate(optionsArray[0]));
      setinputAdvenceSearch(translate(optionsArray[0]));
      
      setItemsReports([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const checkrTypeSelection = () => {
    // Handle the radio button click event for gen_type_manual
  };

  const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean;}>({});
  const [selectAll, setSelectAll] = useState(false);

  const updateCheckboxState = (newItem: { value: any }) => {
    const updatedCheckboxStates = { ...checkboxStates };
    updatedCheckboxStates[newItem.value] = true; // Marquer comme sélectionné
    setCheckboxStates(updatedCheckboxStates);
  };

  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
 // Dans VehicleSelection
 const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, checked } = event.target;
  setCheckboxStates((prevState) => ({
    ...prevState,
    [name]: checked,
  }));

  // Construire la liste d'objets à passer à onVehicleSelect
  const updatedCheckboxStates = {
    ...checkboxStates,
    [name]: checked,
  };

  const anySelected = Object.values(updatedCheckboxStates).some(value => value);
  setCreateButtonDisabled(!anySelected);

  const selectedVehicles = Object.keys(updatedCheckboxStates)
    .filter(key => updatedCheckboxStates[key])
    .map(value => ({ id: value.split('.')[0], value }));
  onVehicleSelect(selectedVehicles);
};

  
const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { checked } = event.target;

  setSelectAll(checked);
  const updatedCheckboxStates: { [key: string]: boolean } = {};
  itemsReports.forEach((item: any) => {
    updatedCheckboxStates[item.value] = checked;
  });
  setCheckboxStates(updatedCheckboxStates);

  // Construire la liste d'objets à passer à onVehicleSelect
  const selectedVehicles = itemsReports.map(item => ({ id: item.id, value: item.value }));
  setCreateButtonDisabled(!checked); // Désactiver le bouton de création si aucune case n'est cochée
  onVehicleSelect(checked ? selectedVehicles : []);
};



  // Fonction appelée lorsque la date de début est modifiée
  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    fromDate(date); // Met à jour la date de début dans le composant parent
  };

  // Fonction appelée lorsque la date de fin est modifiée
  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    todDate(date); // Met à jour la date de fin dans le composant parent
  };

  return ( 
    <div style={{ background: "#fff" }}>
      <div className="row" style={{ marginBottom: "15px", background: "#fff" }}>
        <div className="row">
          <div className="col-md-2" >
            <div
              className="input-group-addon input-sm"
              style={{ paddingRight: "15px", paddingTop: "3px" }}
            >
              {translate("Add report for")}
            </div>
            <div className="d-flex align-items-center">
              <i className={`las la-file-alt icon-report`} style={{ padding: "0px 5px" }} />
              <select
                id="add_report_for"
                name="add_report_for"
                onChange={(e) => addReportBy(e.target.value)}
                className="form-control input-sm"
              >
                <option value="1">{translate("Vehicles")}</option>
                <option value="2">{translate("Fleet")}</option>
                <option value="3">{translate("Drivers")}</option>
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <SelectReport
              controlId="category_vehicule"
              label={translate("Search") + " type"}
              icon={SearchByIcon}
              options={searchTypeOptions}
              valueType={{
                value: inputTypeSearchValue,
                label: translate(inputTypeSearchLabel),
              }}
              onChange={searchBy}
              disabled={SelectReportState}
            />
          </div>
          <div className="col-md-3">
            <div
              className="input-group-addon input-sm"
              style={{ paddingRight: "15px", paddingTop: "3px" } }
            >
              {translate("Search by")}{" "}
            </div>
            <div>
              <div style={{ position: "relative" }}>
                <div className="d-flex align-items-center">
                  <i
                    className={`las la-${autocomplateStateIcon} icon-report`}
                    style={{ padding: "0px 5px" }}
                  />
                  <Form.Control
                    type="text"
                    placeholder={`${translate(inputAdvenceSearch)}`}
                    className="me-2"
                    aria-label={`${translate(inputAdvenceSearch)}`}
                    onChange={(e) => getAutocomplete(e.target.value)}
                    value={itemSearch}
                    disabled={SelectReportState}
                  />
                </div>
                <div
                  className={`autocomplete-items ${autocompleteState}`}
                  style={{ listStyleType: "none", padding: 0 }}
                >
                  {suggestions &&
                    suggestions.map((suggestion: any, index: number) => (
                      <div key={index} onClick={(e) => setItems(suggestion)}>
                        <p> {suggestion.value}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Col md={2} style={{padding: 0}}>
            <div>{translate("Start date")} </div>
            <div>
              <i className="las la-calendar d-inline icon-report"    style={{ padding: "0px 5px" }}></i> 
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="d-inline"
                />
            </div>
          </Col>
          <Col md={3} style={{padding: 0,   paddingLeft: 26}}>
            <div>{translate("End date")} </div>
              <div>
              <i className="las la-calendar d-inline icon-report"    style={{ padding: "0px 5px" }}></i> 
                <DatePicker
                  selected={endDate} 
                  onChange={handleEndDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="d-inline"
                /> 
              </div>
          </Col>
        </div>

        <div className="col-md-12" style={{marginTop: "15px"}}>
          <div
            className="row"
            style={{ marginBottom: "15px", background: "#fff" }}
          >
            <div className="col-md-12">
              <div
                className=""
                style={{
                  padding: "3px 9px",
                  border: "1px solid #ccc",
                  borderBottom: "0px",
                }}
              >
                <input
                  id="mf_cb"
                  name="selectAll"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <span id="mf_title" style={{ fontWeight: "bold" }}>
                  {" "}
                  <span id="nbrofvehicule">({itemsReports.length})</span>
                  {translate("Vehicles")}
                  <div style={{ float: "right" }}>
                    <span className="checkbox" style={{ paddingLeft: "10px" }}>
                      <input
                        type="radio"
                        id="gen_type_manual"
                        name="gen_type"
                        value="manual"
                        onClick={(e) => checkrTypeSelection()}
                      />
                      <label htmlFor="gen_type_manual"></label>
                      <span> {translate("Reports")}</span>
                    </span>
                    <span
                      className="g4n-checkbox"
                      style={{ paddingLeft: "10px" }}
                    >
                      <input
                        type="radio"
                        id="gen_type_auto"
                        name="gen_type"
                        value="auto"
                        onClick={(e) => checkrTypeSelection()}
                      />
                      <label htmlFor="gen_type_auto"></label>
                      <span> {translate("Planning")} </span>
                    </span>
                  </div>
                </span>
              </div>
              <div
                className="table-responsive"
                id="scroll"
                style={{
                  border: "1px solid #ccc",
                  background: "#fff",
                  padding: "5px 5px",
                  marginBottom: "15px",
                  overflow: "auto;",
                  display: "block;",
                  height: "333px",
                }}
              >
                <ul
                  id="mf_list"
                  style={{
                    width: "100%",
                    padding: "5px",
                    listStyleType: "none",
                  }}
                >
                  {itemsReports &&
                    itemsReports.map((itemsReport: any, index: number) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          onChange={handleCheckboxChange}
                          checked={checkboxStates[itemsReport.value]}
                          value={itemsReport.value}
                          name={itemsReport.value}
                        />
                        { } {itemsReport.value}
                      </div>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleSelection;
