import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Bounce, toast } from 'react-toastify';
import { useTranslate } from "./LanguageProvider";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Interface for ticket information
interface TicketInfo {
  summary: string;
  description: string;
  issueType: string;
  issueTypeId: string;
}


interface CreateTicketModalProps {
  show: boolean;
  onHide: () => void;
  refreshTickets: () => void;
}

const CreateTicketModal: React.FC<CreateTicketModalProps> = ({ show, onHide, refreshTickets }) => {

  const userID = localStorage.getItem("userID");
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useTranslate();
  // States for form fields and errors
  const [ticketInfo, setTicketInfo] = useState<TicketInfo>({
    summary: "",
    description: "",
    issueType: "Email request",
    issueTypeId: "10015",
  });
  const [error, setError] = useState<string>("");


  const resetForm = () => {
    setTicketInfo({
      summary: "",
      description: "",
      issueType: "Email request",
      issueTypeId: "10015",
    });
    setError("");
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const jiraHeader = {
      fields: {
        project: {
          key: "GEOT",
        },
        summary: ticketInfo.summary,
        description: {
          type: "doc",
          version: 1,
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: ticketInfo.description,
                },
              ],
            },
          ],
        },
        issuetype: {
          id: ticketInfo.issueTypeId,
          name: ticketInfo.issueType,
        },
      },
    };

    try {
      const response = await axios.post(`${backendUrl}/api/ticket/create`, {
        id_user: userID,
        jira_header: jiraHeader,
      });

      setLoading(false);
      if (response.status === 200) {
      console.log("Ticket  create successfully");
      toast.success(translate("Ticket  create successfully"), {
        position: "bottom-right",
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      resetForm(); // Réinitialiser les champs du formulaire
      onHide(); // Fermer le modal
      refreshTickets();
      
    } else {
      setError("Failed to create ticket");
    }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        // HTTP response error
        console.error("Error creating ticket:", error.response.data);
        setError("Error creating ticket. Please try again.");
      } else if (error.request) {
        // HTTP request error
        console.error("Request error creating ticket:", error.request);
        setError("Error creating ticket. Please try again.");
      } else {
        // Other errors
        console.error("Error creating ticket:", error.message);
        setError(
          "An error occurred while creating the ticket. Please try again."
        );
      }
    }
  };

  // Function to update form fields
  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setTicketInfo((prevState) => {
      if (name === "issueType") {
        let issueTypeId = "10015";
        switch (value) {
          case "Submit a request or incident":
            issueTypeId = "10016";
            break;
          case "Ask a question":
            issueTypeId = "10017";
            break;
          default:
            issueTypeId = "10015";
        }
        return { ...prevState, issueType: value, issueTypeId };
      }
      return { ...prevState, [name]: value };
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Créer un nouveau ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTicketTitle" className="mb-3">
            <Form.Label>Ticket Title:</Form.Label>
            <Form.Control
              type="text"
              name="summary"
              value={ticketInfo.summary}
              onChange={handleChange}
              placeholder="Enter the ticket title"
            />
          </Form.Group>

          <Form.Group controlId="formDescription" className="mb-3">
            <Form.Label>Description:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={ticketInfo.description}
              onChange={handleChange}
              placeholder="Enter the ticket description"
            />
          </Form.Group>

          <Form.Group controlId="formIssueType" className="mb-3">
            <Form.Label>Issue Type:</Form.Label>
            <Form.Control
              as="select"
              name="issueType"
              value={ticketInfo.issueType}
              onChange={handleChange}
            >
              <option value="Email request">Email request</option>
              <option value="Submit a request or incident">
                Submit a request or incident
              </option>
              <option value="Ask a question">Ask a question</option>
            </Form.Control>
          </Form.Group>

          {error && !loading && (
            <div style={{ color: "red" }} className="mb-3">
              {error}
            </div>
          )}

          {loading ? (
            <div className="d-flex justify-content-center mb-3">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Button variant="primary" type="submit" className="mb-3">
              Create Ticket
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateTicketModal;
