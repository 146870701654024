import React from 'react';
import { Popup as LeafletPopup } from 'react-leaflet'; // Importing Popup as LeafletPopup to avoid conflict
import { useTranslate } from '../LanguageProvider';
import { calculateTimeDifferenceInSeconds, formatDurationWithDays, toTimestamp } from '../../utilities/functions';

interface Itinerary {
    id: number;
    start: string;
    end: string;
    name: string;
    distance: number;
    ALT: number;
    LAT: number;
    LNG: number;
    COG: number;
    SOG: number;
    ALARMS: string;
    PRIVATE_MODE: number;
}

interface PopupItineraryProps {
    itinerary: Itinerary;
}

const PopupItinerary: React.FC<PopupItineraryProps> = ({ itinerary }) => {
    const {
        start,
        end,
        name,
        distance,
        LAT,
        LNG,
        SOG,
    } = itinerary;

    const { translate } = useTranslate();
    const exces = 80;
    const excessiveSpeed = SOG > exces ? 'red' : 'green';



    return (
        <LeafletPopup>

            <div className="marker-description" style={{ textAlign: 'left' }}>
                <h6 style={{ fontWeight: 'bold' }}>{name}</h6>
                <p style={{ margin: '5px 0' }} className="popup-val">
                    <i className="las la-clock" title="Start, durée" style={{ marginRight: '0.2em', fontSize: '16px' }}></i>
                    {toTimestamp(start)}
                </p>
                {(name === 'Moteur arrêté' || name === 'Stationnement avec moteur démarré') && (
                    <p style={{ margin: '5px 0' }} className="popup-val">
                        <i className="fa fa-arrow-down" title="End" style={{ marginRight: '0.2em', fontSize: '16px' }}></i>
                        {toTimestamp(end)}
                    </p>


                )}
                {(name === 'Moteur arrêté' || name === 'Stationnement avec moteur démarré') && (
                    <p style={{ margin: '5px 0' }} className="popup-val">
                        <i className="las la-hourglass-end" title="End" style={{ marginRight: '0.2em', fontSize: '16px' }}></i>
                        {formatDurationWithDays(calculateTimeDifferenceInSeconds(new Date(start), new Date(end)))}

                    </p>


                )}
                {name === 'Moteur démarré' && (
                    <p style={{ margin: '5px 0' }} className="popup-val">
                        <i className="las la-tachometer-alt" title="Speed" style={{ marginRight: '0.2em', fontSize: '16px' }}></i>
                        <span className={excessiveSpeed}>{SOG}</span> km/h {excessiveSpeed === "red" && <span className={excessiveSpeed}> - Excess</span>}
                    </p>
                )}
                <p style={{ margin: '5px 0' }} className="popup-val">
                    <i className="las la-map-marker" title="Location" style={{ marginRight: '0.2em', fontSize: '16px' }}></i>
                    {LAT}, {LNG}
                </p>
                <p style={{ margin: '5px 0' }} className="popup-val">
                    <i className="las la-home" title="Adresse" style={{ marginRight: '0.2em', fontSize: '16px' }}></i>
                    <span id={`id-marker-${name}`}></span>
                </p>
                <p style={{ margin: '5px 0' }} className="popup-val">
                    <i className="las la-road" title="Distance" style={{ marginRight: '0.2em', fontSize: '16px' }}></i>
                    {distance / 1000} km
                </p>
            </div>

        </LeafletPopup>
    );
};

export default PopupItinerary;
