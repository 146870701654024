/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, FeatureGroup, useMapEvents, } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-leaflet";
import { useTranslate } from "../components/LanguageProvider";
import IconMarker from "../components/Map/Icon";
import { Col, Row } from "react-bootstrap";
import "../components/Map/map.css";
import VehicleWidget from "../components/Map/vehicleWidget";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { FullscreenControl } from "react-leaflet-fullscreen";
import Slider from "react-slick";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { engineStat, engineStatClass } from "../utilities/functions";
import { useSpring, animated } from "react-spring";
import { LatLngBoundsLiteral, LatLngLiteral, } from "leaflet";
import { IoFemaleSharp } from "react-icons/io5";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Map: React.FC = () => {
  const center: [number, number] = [30.5670197, 4.6911217];
  const zoom: number = 5;
  const MaxZoom: number = 18;
  const MinZoom: number = 5;

  const { translate } = useTranslate();
  const userID = localStorage.getItem("userID");

  const [list_markers, setMarkers] = useState<Vehicle[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [baseMap, setBaseMap] = useState<string>("google_roadmap");
  const [listLayout, setlistLayout] = useState("layoutfloat");
  const [mapLayout, setMapLayout] = useState("col-12");
  const [vehicleDetail, setVehicleDetail] = useState<string>("none");
  const [selectedItem, setSelectedItem] = useState<Vehicle | null>(null);

  const [DistanceSpeedData, setDistanceSpeed] = useState<any>([]);
  const [TemperaturetData, setTemperature] = useState<any>([]);
  const [FuelTankData, setFuelTank] = useState<any>([]);
  const [ItinerarytData, setItineraryData] = useState<any>([]);

  const [clicked, setClicked] = useState(false);
  const [slideState, setSlideState] = useState<string>("slide-state");
  const [ungroupMarker, setUngroupMarker] = useState<boolean>(true);

  const [Driving, setDriving] = useState(0);
  const [Parcking, setParcking] = useState(0);
  const [ParkingEngineRunning, setParkingEngineRunning] = useState(0);
  const [LastTransmission, setLastTransmission] = useState(0);

  const sliderRef = useRef<Slider | null>(null);

  const goToSlide = (index: number) => {
    sliderRef.current?.slickGoTo(index);
  };
  interface toChartData {
    x: number;
    y: number;
  }

  // Replace the GroupComponent declaration with this
  var GroupComponent: React.ComponentType<any> = ungroupMarker
    ? MarkerClusterGroup
    : FeatureGroup;

  interface Vehicle {
    id_vehicule: number;
    immatriculation_vehicule: string;
    LAT: number;
    LON: number;
    SOG: number;
    COG: number;
    ENGINESTAT: number;
    TIMESTAMP: string;
    GPSDIST: number;
    PSN: string;
    nom_conducteur: string;
    nom_groupe: string;
    prenom_conducteur: string;

  }

  // Définir l'animation de clic avec react-spring
  const animationProps = useSpring({
    transform: clicked ? "scale(1.1)" : "scale(1)",
    overflow: "hidden",
    marginTop: clicked ? "55px" : "10px",
  });

  function ToUTCTime(isoDateString: string): number {
    if (!isoDateString) {
      return 0; // Ou une valeur par défaut appropriée
    }

    const dateComponents = isoDateString.split(/[-T:.Z]/);
    const start_date = dateComponents.map((component) =>
      parseInt(component, 10)
    );

    return Date.UTC(
      start_date[0],
      start_date[1] - 1,
      start_date[2],
      start_date[3],
      start_date[4],
      start_date[5],
      0
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableHeight: true,
  };

  const getReportBytype = async (type: number, PSN: string, slide: number) => {
    try {
      const res = await fetch(`${backendUrl}/api/map/reports/${type}/${PSN}`, {
        mode: "cors",
      });

      if (!res.ok) {
        console.error("Erreur lors de la récupération du véhicule");
        return;
      }
      if (slide != -1) goToSlide(slide);
      const data = await res.json();

      console.log(data);

      if (type == 1) setItineraryData(data.data);

      if (type == 4) {
        setSlideState("");

        let previousGPSDIST = data.data[0].GPSDIST / 1000;  // Initialisation avec la première valeur de FUELLVU
        let GPSDISTs = 0;
        const Distance: toChartData[] = data.data.map((item: any) => {
          const currentGPSDIST = item.GPSDIST / 1000;
          const diff = currentGPSDIST - previousGPSDIST;
          previousGPSDIST = currentGPSDIST;  // Mise à jour de previousFUELLVU pour le prochain élément
          GPSDISTs += diff;
          return {
            x: ToUTCTime(item.TIMESTAMP),
            y: Math.round(GPSDISTs),
          };
        });


        const Speed: toChartData[] = data.data.map((item: any) => ({
          x: ToUTCTime(item.TIMESTAMP),
          y: parseFloat(item.SOG),
        }));

        const EngineStat: toChartData[] = data.data.map((item: any) => ({
          x: ToUTCTime(item.TIMESTAMP),
          y: parseFloat(item.ENGINESTAT),
        }));

        setDistanceSpeed({
          chart: {
            zoomType: "x",
            height: 120,
            width: null,
          },
          title: {
            text: "",
            align: "center",
          },
          subtitle: {
            text: "",
            align: "center",
          },
          xAxis: [
            {
              gridLineWidth: 1,
              type: "datetime",
              crosshair: true,
            },
          ],
          yAxis: [
            {
              labels: {
                format: "{value} Km",
                style: {
                  color: "#007bff",
                },
              },
              title: {
                text: translate("Distance"),
                style: {
                  color: "#007bff",
                },
              },
              opposite: true,
            },
            {
              gridLineWidth: 0,
              title: {
                text: translate("Speed"),
                style: {
                  color: "#41b346",
                },
              },
              labels: {
                format: "{value} Km/h",
                style: {
                  color: "#41b346",
                },
              },
            },
            {
              gridLineWidth: 0,
              title: {
                text: translate("Contact"),
                style: {
                  color: "#fd1e0d",
                },
              },
              labels: {
                format: "{value} ",
                style: {
                  color: "#fd1e0d",
                },
              },
              //opposite: true,
            },
          ],
          tooltip: {
            shared: true,
          },
          legend: {
            layout: "horizontal",
            align: "center",
            x: 0,
            verticalAlign: "bottom",
            y: 21,
            floating: true,
          },
          plotOptions: {
            series: {
              turboThreshold: Distance.length, // Adjust this number as needed
            },
          },
          series: [
            {
              name: translate("Distance"),
              type: "spline",
              olor: "#007bff",
              yAxis: 0,
              data: Distance,
              tooltip: {
                valueSuffix: " Km",
              },
            },
            {
              name: translate("Speed"),
              type: "spline",
              color: "#41b346 ",
              data: Speed,
              yAxis: 1,
              tooltip: {
                valueSuffix: " km/h",
              },
            },
            {
              name: translate("Contact"),
              type: "line",
              color: "#fd1e0d",
              yAxis: 2,
              data: EngineStat,
              tooltip: {
                valueSuffix: " ",
              },
            },
          ],

          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    floating: false,
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    x: 0,
                    y: 0,
                  },
                  yAxis: [
                    {
                      labels: {
                        align: "right",
                        x: 0,
                        y: -6,
                      },
                      showLastLabel: false,
                    },
                    {
                      labels: {
                        align: "left",
                        x: 0,
                        y: -6,
                      },
                      showLastLabel: false,
                    },
                    {
                      visible: false,
                    },
                  ],
                },
              },
            ],
          },
        });
      }
      if (type == 8) {
        setFuelTank(data.data);

        setSlideState("");

        const FIRST_FUELLVU = data.data[0].FUELLVU;

        const TFUEL: toChartData[] = data.data.map((item: any) => ({
          x: ToUTCTime(item.TIMESTAMP),
          y: parseFloat((item.FUELLVU - FIRST_FUELLVU).toFixed(2)),
        }));

        console.log(TFUEL);


        let previousVEHDIST = data.data[0].VEHDIST / 1000;  // Initialisation avec la première valeur de FUELLVU
        let VEHDISTs = 0;
        const VEHDIST: toChartData[] = data.data.map((item: any) => {
          const currentVEHDIST = item.VEHDIST / 1000;
          const diff = currentVEHDIST - previousVEHDIST;
          previousVEHDIST = currentVEHDIST;  // Mise à jour de previousFUELLVU pour le prochain élément
          VEHDISTs += diff;
          return {
            x: ToUTCTime(item.TIMESTAMP),
            y: Math.round(VEHDISTs),
          };
        });



        console.log(VEHDIST)

        const FUELLVL: toChartData[] = data.data.map((item: any) => ({
          x: ToUTCTime(item.TIMESTAMP),
          y: Math.round(item.FUELLVL),
        }));

        setFuelTank({
          chart: {
            zoomType: "x",
            height: 120,
          },
          title: {
            text: "",
            align: "center",
          },
          subtitle: {
            text: translate(""),
            align: "center",
          },
          xAxis: [
            {
              gridLineWidth: 1,
              type: "datetime",
              crosshair: true,
            },
          ],
          yAxis: [
            {
              gridLineWidth: 0,
              title: {
                text: translate("Distance"),
                style: {
                  color: "#007bff",
                },
              },
              labels: {
                format: "{value} Km",
                style: {
                  color: "#007bff",
                },
              },
            },
            {
              labels: {
                format: "{value} %",
                style: {
                  color: "#fd1e0d",
                },
              },
              title: {
                text: translate("Tank level"),
                style: {
                  color: "#fd1e0d",
                },
              },
              opposite: true,
            },
            {
              gridLineWidth: 0,
              title: {
                text: translate("Fuel consumption"),
                style: {
                  color: "#0080FF",
                },
              },
              labels: {
                format: "{value} L",
                style: {
                  color: "#0080FF",
                },
              },
              opposite: true,
            },

          ],
          tooltip: {
            shared: true,
          },
          legend: {
            layout: "horizontal",
            align: "center",
            x: 0,
            verticalAlign: "bottom",
            y: 21,
            floating: true,
          },
          plotOptions: {
            series: {
              turboThreshold: VEHDIST.length, // Adjust this number as needed
            },
          },
          series: [
            {
              name: translate("Distance"),
              type: "spline",
              olor: "#007bff",
              yAxis: 0,
              data: VEHDIST,
              tooltip: {
                valueSuffix: " Km",
              },
            },
            {
              name: translate("Fuel consumption"),
              type: "spline",
              color: "#41b346",
              yAxis: 1,
              data: TFUEL,
              /*marker: { 
                    enabled: true
                },*/
              // dashStyle: 'shortdot',
              tooltip: {
                valueSuffix: " L",
              },
            },
            {
              name: translate("Tank level"),
              type: "line",
              color: "#fd1e0d",
              yAxis: 2,
              data: FUELLVL,
              tooltip: {
                valueSuffix: " %",
              },
            },
          ],

          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    floating: false,
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    x: 0,
                    y: 0,
                  },
                  yAxis: [
                    {
                      labels: {
                        align: "right",
                        x: 0,
                        y: -6,
                      },
                      showLastLabel: false,
                    },
                    {
                      labels: {
                        align: "left",
                        x: 0,
                        y: -6,
                      },
                      showLastLabel: false,
                    },
                    {
                      visible: false,
                    },
                  ],
                },
              },
            ],
          },
        });
      }
      if (type == 19) {
        setTemperature(data.data);

        setSlideState("");

        const AVGHUM: toChartData[] = data.data.map((item: any) => ({
          x: ToUTCTime(item.TIMESTAMP),
          y: parseFloat(item.AVGHUM),
        }));

        const AVGTEMP: toChartData[] = data.data.map((item: any) => ({
          x: ToUTCTime(item.TIMESTAMP),
          y: parseFloat(item.AVGTEMP) / 1000,
        }));

        setTemperature({
          chart: {
            zoomType: "x",
            height: 120,
          },
          title: {
            text: translate(""),
            align: "center",
          },
          subtitle: {
            text: translate(
              "Data values are averaged per period. Zoom to view more data"
            ),
            align: "center",
          },
          xAxis: [
            {
              gridLineWidth: 1,
              type: "datetime",
              crosshair: true,
            },
          ],
          yAxis: [
            {
              labels: {
                format: "{value} C°",
                style: {
                  color: "#fd1e0d",
                },
              },
              title: {
                text: translate("Temperature"),
                style: {
                  color: "#fd1e0d",
                },
              },
              opposite: true,
            },
            {
              gridLineWidth: 0,
              title: {
                text: translate("Humidity"),
                style: {
                  color: "#0080FF",
                },
              },
              labels: {
                format: "{value} %",
                style: {
                  color: "#0080FF",
                },
              },
            },
          ],
          tooltip: {
            shared: true,
          },
          legend: {
            layout: "horizontal",
            align: "center",
            x: 0,
            verticalAlign: "bottom",
            y: 21,
            floating: true,
          },
          plotOptions: {
            series: {
              turboThreshold: AVGTEMP.length, // Adjust this number as needed
            },
          },
          series: [
            {
              name: translate("Temperature"),
              type: "spline",
              olor: "#41b346", // Typo: should be "color" instead of "olor"
              yAxis: 0, // Assuming this should be yAxis index 0 for Temperature
              data: AVGTEMP, // Using state variable Temperature
              tooltip: {
                valueSuffix: " C°",
              },
            },
            {
              name: translate("Humidity"),
              type: "spline",
              color: "#41b346", // Typo: should be a different color than the first series
              yAxis: 1, // Assuming this should be yAxis index 1 for Humidity
              data: AVGHUM, // Using state variable Humidity
              tooltip: {
                valueSuffix: " %",
              },
            },
          ],

          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    floating: false,
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    x: 0,
                    y: 0,
                  },
                  yAxis: [
                    {
                      labels: {
                        align: "right",
                        x: 0,
                        y: -6,
                      },
                      showLastLabel: false,
                    },
                    {
                      labels: {
                        align: "left",
                        x: 0,
                        y: -6,
                      },
                      showLastLabel: false,
                    },
                    {
                      visible: false,
                    },
                  ],
                },
              },
            ],
          },
        });
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du véhicule", error);
    }
  };

  interface MarkerData {
    LAT: number;
    LON: number;
  }

  interface MapContentProps {
    markers: MarkerData[];
  }
 

  // Fonction pour calculer les limites à partir des coordonnées des marqueurs
  const calculateBounds = (
    markerLatLngs: LatLngLiteral[]
  ): LatLngBoundsLiteral => {
    let minLat = Number.POSITIVE_INFINITY;
    let maxLat = Number.NEGATIVE_INFINITY;
    let minLng = Number.POSITIVE_INFINITY;
    let maxLng = Number.NEGATIVE_INFINITY;

    for (const { lat, lng } of markerLatLngs) {
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    }

    return [
      [minLat, minLng],
      [maxLat, maxLng],
    ];
  };

  const [userInteraction, setUserInteraction] = useState(false);
  const mapRef = useRef<any>(null);

  // Votre fonction pour recentrer sur le véhicule sélectionné 
  const flyToVehicle = useCallback((map: any, vehicle: Vehicle) => {
    if (vehicle && !userInteraction) {
      // Se recentrer uniquement si pas d'interaction
      console.log(vehicle)
      map.flyTo([vehicle.LAT, vehicle.LON], 15, { duration: 1 });
    }
  }, [userInteraction]);


  // Utilisez `useEffect` pour recentrer lorsque le véhicule sélectionné change  
  useEffect(() => {
    const map = mapRef.current?.leafletElement;
    if (selectedItem && map) {
      flyToVehicle(map, selectedItem);
    }
  }, [selectedItem, flyToVehicle]);


  const basemapOptions = [
    {
      value: "google_roadmap",
      label: "Google Roadmap",
      icon: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    },
    {
      value: "osm",
      label: "OSM",
      icon: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    {
      value: "hot",
      label: "OSM HOT",
      icon: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    },
    {
      value: "dark",
      label: "DARK",
      icon: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png",
    },
    {
      value: "cycle",
      label: "CYCLE MAP",
      icon: "https://dev.{s}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
    },
    {
      value: "google_traffic",
      label: "Google Traffic",
      icon: "https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}",
    },
    {
      value: "google_satellite",
      label: "Google Satellite",
      icon: "https://www.google.com/maps/vt?lyrs=s@189&gl=us&x={x}&y={y}&z={z}",
    },
    {
      value: "google_terrain",
      label: "Google Terrain",
      icon: "https://www.google.com/maps/vt?lyrs=p@189&gl=us&x={x}&y={y}&z={z}",
    },
    //{ value: 'mapbox', label: 'Mapbox', icon: 'https://api.mapbox.com/styles/v1/idenet/ckoytzfxw0mr417mju7fh5kc5.html?title=view&access_token=pk.eyJ1IjoiaWRlbmV0IiwiYSI6ImNqeGs0dHAzbTFlb3UzeXFkOWk1ZWQ5cDIifQ.lpPl03eDGutSjsd0fLaPkw&zoomwheel=true&fresh=true#9/37.78/-122.4241' },
  ];

  useEffect(() => {
    const getMarkers = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/map/find/${userID}`, {
          mode: "cors",
        });
        if (response.ok) {
          const data = await response.json();
          setMarkers(data);

          // Calcul du nombre de véhicules en démarrage, en stationnement, et en stationnement avec moteur démarré
          const startingVehicles = data.filter(
            (vehicle: Vehicle) => vehicle.ENGINESTAT == 1 && vehicle.SOG > 5
          );

          const parkingVehicles = data.filter(
            (vehicle: Vehicle) => vehicle.ENGINESTAT == 0
          );

          const runningVehicles = data.filter(
            (vehicle: Vehicle) => vehicle.ENGINESTAT == 1 && vehicle.SOG < 5
          );

          const filteredVehicles = data.filter((vehicle: Vehicle) => {
            const lastTransmissionHours = calculateHoursDifference(
              new Date().toISOString(),
              timeZone(vehicle.TIMESTAMP)
            );
            return lastTransmissionHours > 1;
          });

          setDriving(startingVehicles.length);
          setParcking(parkingVehicles.length);
          setParkingEngineRunning(runningVehicles.length);
          setLastTransmission(filteredVehicles.length);
        } else {
          console.error("Failed to fetch vehicle data");
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      }
    };

    getMarkers();

    // Définir un intervalle pour rafraîchir toutes les minutes
    const refreshInterval = setInterval(() => {
      getMarkers();
    }, 6000);

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(refreshInterval);
  }, [userID]);

  const filteredItems = list_markers.filter((item) =>
    item.immatriculation_vehicule.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (item.nom_conducteur && item.nom_conducteur.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (item.prenom_conducteur && item.prenom_conducteur.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Fonction pour calculer la différence en heures entre deux timestamps
  function calculateHoursDifference(
    timestamp1: string,
    timestamp2: string
  ): number {
    const diffInMilliseconds =
      new Date(timestamp1).getTime() - new Date(timestamp2).getTime();
    return diffInMilliseconds / (1000 * 60 * 60);
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setVehicleDetail("none");
  };

  const handleItemClick = (item: Vehicle) => {

    if (!mapRef.current) return;

    const map = mapRef.current; // Accéder à l'instance de la carte
    setVehicleDetail("flex");
    setSearchTerm(item.immatriculation_vehicule);

    setSelectedItem(item); // Mettre à jour l'élément sélectionné
    setUserInteraction(false); // Réinitialiser l'interaction utilisateur

    // Centrer la carte sur le véhicule sélectionné
    map.flyTo([item.LAT, item.LON], 15, { duration: 1 });
    setClicked(true);
  };

  const handleOnCloseWidget = () => {
    console.log("item");

    setVehicleDetail("none");

    setSearchTerm("");

    setSlideState("slide-state");

    setClicked(false);
  };

  const handleItineraryClick = (PSN: string) => {
    console.log("Type: 1");

    getReportBytype(1, PSN, -1);
  };

  const handleDistanceSpeedClick = (PSN: string) => {
    console.log("Type: 4");

    getReportBytype(4, PSN, 0);
  };

  const handleFuelTankClick = (PSN: string) => {
    console.log("Type: 8");

    getReportBytype(8, PSN, 1);

    goToSlide(1);
  };

  const handleTemperatureDiagramClick = (PSN: string) => {
    console.log("Type: 19");

    getReportBytype(19, PSN, 2);
  };

  const handleGroupMarker = () => {
    ungroupMarker == false ? setUngroupMarker(true) : setUngroupMarker(false);
  };

  interface BasemapsDict {
    google_roadmap: string;
    osm: string;
    hot: string;
    dark: string;
    cycle: string;
    google_traffic: string;
    google_satellite: string;
    google_terrain: string;
    //  mapbox: string;
    [key: string]: string;
  }

  const basemapsDict: BasemapsDict = {
    google_roadmap: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    osm: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    hot: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    dark: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png",
    cycle: "https://dev.{s}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
    google_traffic:
      "https://mt0.google.com/vt?lyrs=h@221097413,traffic&x={x}&y={y}&z={z}",
    google_satellite:
      "https://www.google.com/maps/vt?lyrs=s@189&gl=us&x={x}&y={y}&z={z}",
    google_terrain:
      "https://www.google.com/maps/vt?lyrs=p@189&gl=us&x={x}&y={y}&z={z}",
    // mapbox: 'https://api.mapbox.com/styles/v1/idenet/ckoytzfxw0mr417mju7fh5kc5.html?title=view&access_token=pk.eyJ1IjoiaWRlbmV0IiwiYSI6ImNqeGs0dHAzbTFlb3UzeXFkOWk1ZWQ5cDIifQ.lpPl03eDGutSjsd0fLaPkw&zoomwheel=true&fresh=true#9/37.78/-122.4241',
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleWheelScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
    console.log("Wheel event triggered");
    const container = containerRef.current;
    if (container) {
      container.scrollTop += event.deltaY;
    }
  };


  const mapLayoutClick = () => {
    listLayout == "layoutfloat"
      ? setlistLayout("layoutleft")
      : setlistLayout("layoutfloat");
    mapLayout == "col-12" ? setMapLayout("col-9") : setMapLayout("col-12");

    console.log("Clic sur map-layout");
  };

  const [vehicleState, setVehicleState] = useState<string | null>(null);

  const handleMarker = (state: string) => {
    if (vehicleState === state) {
      setVehicleState(null); // If the filter is already selected, clear the filter
    } else {
      setVehicleState(state); // Otherwise, apply the filter
    }
  };

  const timeZone = (datetime: string) => {
    const originalDate = new Date(datetime);
    const newDate = new Date(originalDate.getTime());

    // Ajouter une heure pour l'heure d'été
    newDate.setHours(newDate.getHours() + 1);

    return newDate.toLocaleString(); // Convertir en chaîne de caractères pour l'affichage
  };


  const MapEventHandler: React.FC<{
    onMapReady: (mapInstance: any) => void
  }> = ({ onMapReady }) => {
    const map = useMap(); useEffect(() => {
      if (map) {
        onMapReady(map);
        // Appeler la fonction lorsque la carte est prête   
      }
    }, [map]); return null;
  };

  
  return (
    <>
      <Row>
        <div
          className={`${listLayout} col-12 col-md-3`}
          style={{ zIndex: 9999, paddingLeft: "30px", margin: " 20px 0" }}
        >
          <div style={{ margin: "0px 0 15px 0" }} className="">
            <button
              type="button"
              data-toggle="tooltip"
              className={`btn btn-default btn-sm pull-left act ${vehicleState !== "move" ? "active" : ""
                }`}
              id="act_moving"
              onClick={() => handleMarker("move")}
              title={translate("Engine running")}
            >
              <span>{Driving} </span>
              <i
                className="la la-play-circle"
                style={{ color: "green", fontSize: "20px" }}
              ></i>
            </button>
            <button
              type="button"
              data-toggle="tooltip"
              className={`btn btn-delault btn-sm pull-left act ${vehicleState !== "pause" ? "active" : ""
                }`}
              id="act_pause"
              onClick={() => handleMarker("pause")}
              title={translate("Parking with engine started")}
            >
              <span>{ParkingEngineRunning} </span>
              <i
                className="la la-pause-circle"
                style={{ color: "red", fontSize: "20px" }}
              ></i>
            </button>
            <button
              type="button"
              data-toggle="tooltip"
              className={`btn btn-delault btn-sm pull-left act ${vehicleState !== "stop" ? "active" : ""
                }`}
              id="act_stoping"
              onClick={() => handleMarker("stop")}
              title={translate("Parking")}
            >
              <span>{Parcking} </span>
              <i
                className="las la-stop-circle"
                style={{ color: "blue", fontSize: "20px" }}
              ></i>
            </button>
            <button
              type="button"
              data-toggle="tooltip"
              className={`btn btn-delault btn-sm pull-left act ${vehicleState !== "absence" ? "active" : ""
                }`}
              id="act_absence"
              onClick={() => handleMarker("absence")}
              title={translate("Last transmission")}
            >
              <span>{LastTransmission} </span>
              <i
                className="la la-history"
                style={{ color: "#000", fontSize: "20px" }}
              ></i>
            </button>
            <button
              type="button"
              data-toggle="tooltip"
              className="btn btn-delault btn-sm pull-left act"
              id="act_regrouper"
              onClick={() => handleGroupMarker()}
              title={translate("Group or ungroup vehicles")}
            >
              <i
                className="la la-object-ungroup"
                style={{ color: "#000", fontSize: "20px" }}
              >
                {" "}
              </i>
            </button>
          </div>
          <span className="map-search">
            <input
              type="text"
              placeholder={translate("Search vehicle, driver...")}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            ({list_markers.length}) <i className="las la-search"></i>
          </span>
          <span className="map-layout" onClick={mapLayoutClick}>
            <i
              className="las la-list"
              data-rel="bootstrap-tooltip"
              title={translate("Extend")}
              style={{ fontSize: "24px" }}
            ></i>
          </span>

          <animated.div
            className="map-list"
            ref={containerRef}
            onWheel={handleWheelScroll}
            tabIndex={0}
            style={animationProps}
          >
            {filteredItems.map(
              (item) =>
                (vehicleState === null ||
                  vehicleState ===
                  engineStatClass(item.ENGINESTAT, item.SOG)) && (
                  <div
                    key={item.id_vehicule}
                    id={item.id_vehicule.toString()}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleItemClick(item);
                    }}
                    className={`VehicleWidget ${engineStatClass(
                      item.ENGINESTAT,
                      item.SOG
                    )}`}
                  >
                    <VehicleWidget
                      id={item.id_vehicule}
                      matriculation={item.immatriculation_vehicule}
                      timestamp={timeZone(item.TIMESTAMP)}
                      SOG={item.SOG}
                      LAT={item.LAT}
                      LON={item.LON}
                      COG={item.COG}
                      enginestat={item.ENGINESTAT}
                      gpsdist={item.GPSDIST}
                      detail={vehicleDetail}
                      PSN={item.PSN}
                      nom_conducteur={item.nom_conducteur}
                      nom_groupe={item.nom_groupe}
                      prenom_conducteur={item.prenom_conducteur}
                      handleItineraryClick={handleItineraryClick}
                      handleDistanceSpeedClick={handleDistanceSpeedClick}
                      handleFuelTankClick={handleFuelTankClick}
                      handleTemperatureDiagramClick={
                        handleTemperatureDiagramClick
                      }
                      onClose={handleOnCloseWidget}
                    />
                  </div>
                )
            )}
          </animated.div>
        </div>
        <div className={mapLayout} style={{ padding: "0px" }}>
          <MapContainer
            center={center}
            zoom={zoom}
            minZoom={MinZoom}
            maxZoom={MaxZoom}
            style={{
              padding: 0,
              margin: 0,
              height: "86vh",
              width: "100%",
              zIndex: 1,
            }}

          >
            {/* <MapContent /> */}

            <MapEventHandler onMapReady={(mapInstance) => { mapRef.current = mapInstance; }} />

            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={basemapsDict[baseMap]}
            />
            <div className="basemaps-container">
              <select
                value={baseMap}
                onChange={(e) => setBaseMap(e.target.value)}
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}asset/images/mapicon/layers.png)`,
                  appearance: "none",
                  backgroundSize: "22px",
                  backgroundPosition: " center center",
                  backgroundRepeat: "no-repeat",
                  border: "none",
                  color: "transparent",
                  outline: "none",
                }}
              >
                {basemapOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    style={{ color: "black" }}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <GroupComponent>

              {list_markers.map((item) => (
                <Marker
                  key={item.id_vehicule}
                  position={[item.LAT, item.LON]}
                  icon={IconMarker(
                    item.COG,
                    "asset/images/mapicon/car.svg",
                    () => handleItemClick(item)
                  )}
                >
                  <Popup>
                    <span onClick={() => handleItemClick(item)}>
                      {" "}
                      <img
                        src={
                          engineStat(item.ENGINESTAT, item.COG, translate)
                            .iconState
                        }
                        alt=""
                      />{" "}
                      {item.immatriculation_vehicule}
                    </span>
                  </Popup>
                </Marker>
              ))}
            </GroupComponent>
            <FullscreenControl
              position="topright"
              title="Show me the fullscreen map"
              forceSeparateButton={false}
            />
            {/* <MapCenter /> */}

          </MapContainer>
        </div>
        <div
          className="layoutfloat"
          style={{
            zIndex: 9999,
            background: " #fff",
            bottom: "0px",
            border: " 1px solid #ddd; ",
            padding: "0",
          }}
        >
          <Slider ref={sliderRef} {...settings} className={`${slideState}`}>
            <div className="dvc">
              <i
                className="las la-times"
                style={{
                  position: "absolute",
                  zIndex: 9999,
                  border: "1px solid #ddd",
                  borderRadius: "19px",
                  padding: "6px",
                }}
                onClick={() => {
                  setSlideState("slide-state");
                }}
              ></i>
              <Row>
                <Col xs={12}>
                  <h5 style={{ textAlign: "center" }}>
                    {translate("Diagram of distance, speed and contact")}
                  </h5>
                  {DistanceSpeedData &&
                    Object.keys(DistanceSpeedData).length > 0 ? (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "0",
                        margin: "8",
                      }}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={DistanceSpeedData}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "16px",
                        margin: "8px",
                        textAlign: "center",
                      }}
                    >
                      {translate("No data to display No data to display for distance, speed and contact")}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="dvc">
              <i
                className="las la-times"
                style={{
                  position: "absolute",
                  zIndex: 9999,
                  border: "1px solid #ddd",
                  borderRadius: "19px",
                  padding: "6px",
                }}
                onClick={() => {
                  setSlideState("slide-state");
                }}
              ></i>

              <Row>
                <h5 style={{ textAlign: "center" }}>
                  {translate("Fuel Tank Diagram")}
                </h5>
                <Col xs={12}>
                  {FuelTankData && Object.keys(FuelTankData).length > 0 ? (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "0",
                        margin: "8",
                      }}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={FuelTankData}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "16px",
                        margin: "8px",
                        textAlign: "center",
                      }}
                    >
                      {translate("No data to display for fuel tank graph")}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="dvc">
              <i
                className="las la-times"
                style={{
                  position: "absolute",
                  zIndex: 9999,
                  border: "1px solid #ddd",
                  borderRadius: "19px",
                  padding: "6px",
                }}
                onClick={() => {
                  setSlideState("slide-state");
                }}
              ></i>

              <Row>
                <Col xs={12}>
                  <h5 style={{ textAlign: "center" }}>{translate("Temperature Diagram")}</h5>
                  {TemperaturetData &&
                    Object.keys(TemperaturetData).length > 0 ? (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "0",
                        margin: "8",
                      }}
                    >
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={TemperaturetData}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "16px",
                        margin: "8px",
                        textAlign: "center",
                      }}
                    >
                      {translate("No data to display for temperature graph")}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Slider>
        </div>
      </Row>
    </>
  );
};

export default Map;
