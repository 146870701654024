import React from 'react';

const ChatSidebarRight: React.FC = () => { 
  return (
    <div className="col app-chat-sidebar-right app-sidebar overflow-hidden" id="app-chat-sidebar-right">
      <div className="sidebar-header d-flex flex-column justify-content-center align-items-center flex-wrap px-6 pt-12">
        <div className="avatar avatar-xl avatar-online chat-sidebar-avatar">
          <img src="../asset/images/avatars/4.png" alt="Avatar" className="rounded-circle" />
        </div>
        <h5 className="mt-4 mb-0">Felecia Rower</h5>
        <span>NextJS Developer</span>
        <i className="ti ti-x ti-lg cursor-pointer close-sidebar"></i>
      </div>
      <div className="sidebar-body p-6 pt-0">
        <div className="my-6">
          <p className="text-uppercase mb-1 text-muted">About</p>
          <p className="mb-0">
            It is a long established fact that a reader will be distracted by the readable content.
          </p>
        </div>
        <div className="my-6">
          <p className="text-uppercase mb-1 text-muted">Personal Information</p>
          <ul className="list-unstyled d-grid gap-4 mb-0 ms-2 py-2 text-heading">
            <li className="d-flex align-items-center">
              <i className="ti ti-mail ti-md"></i>
              <span className="align-middle ms-2">josephGreen@email.com</span>
            </li>
            <li className="d-flex align-items-center">
              <i className="ti ti-phone-call ti-md"></i>
              <span className="align-middle ms-2">+1(123) 456 - 7890</span>
            </li>
            <li className="d-flex align-items-center">
              <i className="ti ti-clock ti-md"></i>
              <span className="align-middle ms-2">Mon - Fri 10AM - 8PM</span>
            </li>
          </ul>
        </div>
        <div className="my-6">
          <p className="text-uppercase text-muted mb-1">Options</p>
          <ul className="list-unstyled d-grid gap-4 ms-2 py-2 text-heading">
            <li className="cursor-pointer d-flex align-items-center">
              <i className="ti ti-badge ti-md"></i>
              <span className="align-middle ms-2">Add Tag</span>
            </li>
            <li className="cursor-pointer d-flex align-items-center">
              <i className="ti ti-star ti-md"></i>
              <span className="align-middle ms-2">Important Contact</span>
            </li>
            <li className="cursor-pointer d-flex align-items-center">
              <i className="ti ti-photo ti-md"></i>
              <span className="align-middle ms-2">Shared Media</span>
            </li>
            <li className="cursor-pointer d-flex align-items-center">
              <i className="ti ti-trash ti-md"></i>
              <span className="align-middle ms-2">Delete Contact</span>
            </li>
            <li className="cursor-pointer d-flex align-items-center">
              <i className="ti ti-ban ti-md"></i>
              <span className="align-middle ms-2">Block Contact</span>
            </li>
          </ul>
        </div>
        <div className="d-flex mt-6">
          <button className="btn btn-danger w-100">
            Delete Contact<i className="ti ti-trash ti-16px ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatSidebarRight;
