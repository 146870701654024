import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select, { components } from "react-select";





interface SelectUsersProps {
  controlId: string;
  label: string;
  icon: string;
  options: any[];
  valueType: { value: number; label: string }[];
  onChange?: (selectedOption: any) => void;
  onClick?: (selectedOption: any) => void;
  handleCategoryChange?: (selectedOption: any) => void;
  placeholder?: string;
  noOptionsMessage?: string;
  col?: string;
  name?: string;
  isMulti: boolean;
}

const SelectUsers: React.FC<SelectUsersProps> = ({
  controlId,
  label,
  icon,
  options,
  valueType,
  onChange,
  onClick,
  handleCategoryChange,
  placeholder,
  noOptionsMessage,
  name
}) => {
  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className={`las la-${icon}`} style={{ marginRight: '8px' }}></i>
            {props.children}
          </div>
        </components.DropdownIndicator>
      )
    );
  };

  // Custom function to render no options message
  const renderNoOptionsMessage = ({ inputValue, ...props }: { inputValue: string }) => {
    return (
      <div {...props}>
        {noOptionsMessage ? noOptionsMessage.replace("{inputValue}", inputValue) : "Aucune option pour " + inputValue}
      </div>
    );
  };

  // Handle option click
  const handleOptionClick = (selectedOption: any) => {
    if (onClick) {
      onClick(selectedOption);
    }
  };

  return (
    <Form.Group controlId={controlId} as={Row} style={{ marginTop: "15px" }}>
      <Col sm="12" md="12" lg="12" styles={{
        maxHeight: '200px', 
        overflowY: 'auto',
      }}> 
        <Select
          options={options}
          value={valueType}
          name={name}
          onChange={(selectedOption) => {
            if (onChange) {
              onChange(selectedOption);
            }
            // Simulate click handling
            handleOptionClick(selectedOption);
          }}
          components={{ DropdownIndicator }}
          className="w-100"
          id={name}
          placeholder={placeholder}
          noOptionsMessage={renderNoOptionsMessage}
          isMulti
        />
      </Col>
    </Form.Group>
  );
};

export default SelectUsers;
