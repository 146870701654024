import { divIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { engineStatItinerary } from '../../utilities/functions';
import { useTranslate } from '../LanguageProvider';


const IconMarker = (direction: number,speed: number,name: string, onClick: () => void) => {  
  const { translate } = useTranslate(); 

  const state = name === 'Moteur démarré' ? 1 : 0;
  
  const { iconState } = engineStatItinerary(state, speed, translate); 
  const iconStyle = {
    width: '15px',
    height: '15px',
    transform: `rotate(${direction}deg)`,
  };
 

  // Render the icon state with rotation
  const iconMarker = `<img style="margin-bottom: 0px; transform: rotate(${direction}deg);" src="${iconState}" />`;

  // Render the component as a string for Leaflet's divIcon
  const htmlString = renderToString(
    
    <div  onClick={onClick}> 
       <img style={{ height: "28px", right: "38px", marginLeft: "-3px", marginTop: "-3px"}} src="https://idegps.net/img/mapicon/marker.png" />
       <img className="overlay-img" style={{ width: '15px', height: '15px', transform: state == 1 && speed>5 ? `rotate(${direction}deg)` : `rotate(0 deg)`, }} src={iconState} />
    </div>
  );

 
  return divIcon({
    className: 'custom-icon',
    iconSize: [15, 15], 
    iconAnchor: [15, 15],
    popupAnchor: [0, -30],
    html: htmlString ,
  });
};

export default IconMarker;


