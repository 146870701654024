import React from 'react';
import ListFleetReports from './ListFleetReports';


interface ReportSelectionProps {
  selectedReports: number[];
  onReportSelect: (reports: number[]) => void;
}

const ReportFleetSelection: React.FC<ReportSelectionProps> = ({ selectedReports, onReportSelect }) => {
  return (
    <div>
      <ListFleetReports 
        selectedReports={selectedReports}
        onReportsChange={(reports) => onReportSelect(reports)}
      />
    </div>
  );
}

export default ReportFleetSelection; 

// Ajouter une déclaration d'exportation vide
export {};