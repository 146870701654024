import React, { useState, useEffect, useRef } from 'react';
import ChatInput from './ChatInput';
import { Dropdown, Button } from 'react-bootstrap';



interface Message {
  id: number;
  text: string[];
  time: string;
  isRight: boolean;
  avatar: string;
}

const ChatHistory: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const chatEndRef = useRef<HTMLDivElement>(null);

  const defaultMessages: Message[] = [
    {
      id: 1,
      text: ["How can we help? We're here for you! 😄"],
      time: "10:00 AM",
      isRight: true,
      avatar: "../asset/images/avatars/1.png",
    },
    {
      id: 2,
      text: [
        "Hey John, I am looking for the best admin template.",
        "Could you please help me to find it out? 🤔",
        "It should be Bootstrap 5 compatible.",
      ],
      time: "10:02 AM",
      isRight: false,
      avatar: "../asset/images/avatars/4.png",
    },
    {
      id: 3,
      text: ["Vuexy has all the components you'll ever need in an app."],
      time: "10:03 AM",
      isRight: true,
      avatar: "../asset/images/avatars/1.png",
    },
    {
      id: 4,
      text: ["Vuexy has all the components you'll ever need in an app."],
      time: "10:03 AM",
      isRight: true,
      avatar: "../asset/images/avatars/1.png",
    },

  ];

  useEffect(() => {
    setMessages(defaultMessages);
  }, []);

  useEffect(() => {
    // Défiler vers le bas lorsque les messages changent
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);


  const handleSendMessage = (text: string) => {
    const newMessage: Message = {
      id: messages.length + 1, // Assigner un ID unique
      text: [text],
      time: new Date().toLocaleTimeString(), // Utiliser l'heure actuelle
      isRight: true, // Vous pouvez ajuster ceci en fonction des besoins
      avatar: "../asset/images/avatars/1.png", // Vous pouvez ajuster ceci en fonction des besoins
    };
    setMessages([...messages, newMessage]);
  };

  return (
    <> 
       <style type="text/css">
        {`
          .dropdown-toggle::after {
            display: none !important;
          }
        `}
      </style>
 
    <div
      className="col app-chat-history"
    >

      <div className="chat-history-wrapper">
        <div className="chat-history-header border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex overflow-hidden align-items-center">
              <i className="ti ti-menu-2 ti-lg cursor-pointer d-lg-none d-block me-4"></i>
              <div className="flex-shrink-0 avatar avatar-online">
                <img src="../asset/images/avatars/4.png" alt="Avatar" className="rounded-circle" style={{ height: "41px" }} />
              </div>
              <div className="chat-contact-info flex-grow-1 ms-4">
                <h6 className="m-0 fw-normal">Felecia Rower</h6>
                <small className="user-status text-body">NextJS developer</small>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <i className="las la-phone-alt cursor-pointer" style={{
                transition: 'background-color 0.3s ease',
                padding: '10px',
                borderRadius: '50%',
                fontSize: '20px'
              }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}> </i>
              <i className="las la-video cursor-pointer " style={{
                transition: 'background-color 0.3s ease',
                padding: '10px',
                borderRadius: '50%',
                fontSize: '20px'
              }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}></i>
              <i className="las la-search" style={{
                transition: 'background-color 0.3s ease',
                padding: '10px',
                borderRadius: '50%',
                fontSize: '20px'
              }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}></i>

              <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  variant="light"
                  className="icon-wrapper"
                  style={{
                    padding: '10px',
                    borderRadius: '50%',
                    fontSize: '20px',
                    border: 'none'
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'lightgrey'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                >
                  <i className="las la-ellipsis-v"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  <Dropdown.Item href="#">View Contact</Dropdown.Item>
                  <Dropdown.Item href="#">Mute Notifications</Dropdown.Item>
                  <Dropdown.Item href="#">Block Contact</Dropdown.Item>
                  <Dropdown.Item href="#">Clear Chat</Dropdown.Item>
                  <Dropdown.Item href="#">Report</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="chat-history-body"
          style={{
            display: 'flex',
            flexDirection: 'column', // Affiche les éléments de bas en haut
            overflowY: 'auto', // Ajoute le défilement vertical

          }}
        >
          <ul className="list-unstyled chat-history">
            {messages.map((message) => (
              <li key={message.id} className={`chat-message ${message.isRight ? 'chat-message-right' : 'chat-message-left'}`}>
                <div className="d-flex overflow-hidden">
                  {!message.isRight && (
                    <div className="user-avatar flex-shrink-0">
                      <div className="avatar avatar-sm">
                        <img src={message.avatar} alt="Avatar" className="rounded-circle" style={{ height: "41px" }} />
                      </div>
                    </div>
                  )}
                  <div className="chat-message-wrapper flex-grow-1">
                    {message.text.map((line, index) => (
                      <div key={index} className={`chat-message-text ${index > 0 ? 'mt-2' : ''}`}>
                        <p className="mb-0">{line}</p>
                      </div>
                    ))}
                    <div className={`text-${message.isRight ? 'end' : 'start'} text-muted mt-1`}>
                      {message.isRight && <i className="ti ti-checks ti-16px text-success me-1"></i>}
                      <small>{message.time}</small>
                    </div>
                  </div>
                  {message.isRight && (
                    <div className="user-avatar flex-shrink-0">
                      <div className="avatar avatar-sm">
                        <img src={message.avatar} alt="Avatar" className="rounded-circle" style={{ height: "41px" }} />
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <div ref={chatEndRef} />
        </div>
        <div className="chat-input" style={{ padding: '1rem', borderTop: '1px solid #ccc', backgroundColor: 'white', position: 'sticky', bottom: 0 }}>
          <ChatInput onSendMessage={handleSendMessage} />
        </div>
      </div>
    </div>
    </>
  );
 
};

export default ChatHistory;
