/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import { translate } from 'pdf-lib';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, NavLink, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { PropagateLoader } from 'react-spinners';
import { useTranslate } from '../LanguageProvider';
import { formatToTimestamp, toTimestamp } from '../../utilities/functions';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Définition de l'interface pour les données de rapport
interface ReportData {
  id_repport: number;
  driver_name: string;
  ibutton_code: string;
  psn: string;
  distance: number;
  acc: number;
  brk: number;
  max_speed: number;
  duration: string;
  start: string;
  end: string;
  score: number;
  rest_time: number;
  driving_time: number;
}

interface recapData {
  id_repport: number;
  type_report: number;
  turn_report: number;
  date_creation: string;
  date_debut: string;
  date_fin: string;
}

interface Report35Props {
  type_report?: string;
  turn?: string;
  id_report?: string;
}

const Report35: React.FC<Report35Props> = ({ type_report, turn, id_report }) => {

  const [sortConfig, setSortConfig] = useState<{ key: keyof ReportData; direction: 'ascending' | 'descending' } | null>(null);


  const { translate } = useTranslate();
  const [Reports, setReports] = useState([]);
  const [recapReport, setRecapReport] = useState<recapData>();
  const [pageCount, setPageCount] = useState(0);
  let [total, setTotalPage] = useState(0);
  let [limit, setSelectedlimit] = useState(10);
  const [loading, setLoading] = useState(true); // Add loading state
  const [colum, setSortColum] = useState("driver_name");
  const [sort, setSort] = useState("ASC");
  const [search, setSearch] = useState("");
  const [type, setType] = useState(0);
  const [typeSearch, setTypeSearch] = useState(translate(translate("Report ID")));

  const [showDropdown, setShowDropdown] = useState<string>("");

  const [InputAdvancedSearch, setInputAdvancedSearch] = useState<string>("Vehicle");
  const [inputTypeSearch, setInputTypeSearch] = useState<number>(0);


  let page = 1;

  interface MenuItem {
    id: number;
    fieldName: string;
    label: string;
  }

  const MenuItems: MenuItem[] = [
    { id: 0, fieldName: translate('Vehicle'), label: translate('Vehicle') },
    { id: 1, fieldName: translate('Group'), label: translate('Vehicles group') },
    { id: 2, fieldName: 'PSN', label: 'PSN' },
    { id: 3, fieldName: translate('User'), label: translate('User') },
  ];

  function copyToClipboard(tagValue: any) {
    // Créer un élément textarea temporaire pour copier le contenu
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = tagValue;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
  }

  const [selectedColumns, setSelectedColumns] = useState({
    id_repport: true,
    driver_name: true,
    ibutton_code: true,
    psn: true,
    distance: true,
    acc: true,
    brk: true,
    max_speed: true,
    duration: true,
    start: true,
    end: true,
    score: true,
    rest_time: true,
    driving_time: true
  });

  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };


  const handleSortingColum = (curentColum: string) => {

    setSortColum(curentColum)
    sort === "ASC" ? setSort("DESC") : setSort("ASC");
    getReport(limit, page, search, type, colum, sort);
  };


  const handleAdvancedSearch = async (event: any) => {
    const newValue = event.target.value;
    setSearch(newValue);
    await getReport(limit, page, newValue, type, colum, sort);
  };


  const handlePageClick = async (data: any) => {
    page = data.selected + 1;
    const commentsFormServer = await getReport(limit, page, search, type, colum, sort);
    setReports(commentsFormServer);
    window.scrollTo(0, 0)
  };

  const handleItemClick = (fieldId: number, fieldName: string) => {

    console.log('Clicked on:', fieldName);

    setInputAdvancedSearch(fieldName);
    setInputTypeSearch(fieldId);
    setShowDropdown("");

  };


  // Function to handle the change in the select element
  const handleSelectChange = async (event: any) => {
    const newValue = event.target.value;
    setSelectedlimit(newValue);
    const commentsFormServer = await getReport(limit, newValue, search, type, colum, sort);

    setReports(commentsFormServer);

  };

  const userID = localStorage.getItem("userID");


  const getReport = async (limit: number,
    page: number,
    search: string,
    type: number,
    colum: string,
    sort: string) => {
    try {


      const bodyData = JSON.stringify({
        limit: limit,
        page: page,
        search: search,
        type: type,
        turn: turn,
        colum: colum,
        sort: sort,
      });



      const total_pages = await fetch(`${backendUrl}/api/report35/totalpage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });


      const totalPages = await total_pages.json();
      total = totalPages[0]["total"];
      setTotalPage(totalPages[0]["total"]);


      const res = await fetch(`${backendUrl}/api/report35`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyData,
        mode: "cors",
      });



      const data = await res.json();
      setPageCount(Math.ceil(total / limit));
      setReports(data["reportData"]);
      setRecapReport(data["recap"])

      return data["reportData"];

    } catch (error) {
      console.error(error);

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {



    getReport(limit, page, search, type, colum, sort);

  }, [userID, limit]);



  // Function to determine the background color based on the total score
  const getScoreClass = (score: number) => {
    if (score < 2) return 'table-success';
    if (score >= 2 && score < 5) return 'table-warning';
    if (score >= 5) return 'table-danger';
    return '';
  };
  const calculateDrivingTime = (drivingTime: number, hoursAuthorized: number) => {
    let drivingExceeded = 'D: 00h 00m 00s';
    let hosPenalty = 0;
    let positive = 0;

    if (drivingTime - hoursAuthorized > 0) {
      drivingExceeded = formatDuration((drivingTime - hoursAuthorized) * 60 * 60);
      hosPenalty = (drivingTime - hoursAuthorized) * 60 / 10;
      positive = 1;
    }

    return { hosPenalty, drivingExceeded, positive };
  };

  const calculateRestTime = (restTime: number, hoursAuthorized: number) => {
    let restExceeded = 'R: 00h 00m 00s';
    let hosPenalty = 0;
    let positive = 0;

    if (hoursAuthorized - restTime > 0) {
      restExceeded = formatDuration(restTime * 60 * 60);
      hosPenalty = restTime * 60 / 10;
      positive = 1;
    }

    return { hosPenalty, restExceeded, positive };
  };

  // Fonction pour formater la durée en 'Hh Mm Ss'
  const formatDuration = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds); // spécifier la valeur en secondes
    return date.toISOString().substr(11, 8);
  };


  const handleTypeSearch = (selectedValue: string) => {

    console.log(selectedValue)
    switch (selectedValue) {
      case translate("Report ID"):
        console.log(0)
        setType(0);
        break;
      case translate("Driver name"):
        console.log(1)
        setType(1);
        break;
      case translate("iButton code"):
        console.log(2)
        setType(2);
        break;
      case translate("PSN"):
        console.log(3)
        setType(3);
        break;
      case translate("Distance"):
        console.log(4)
        setType(4);
        break;
      case translate("ACC"):
        console.log(5)
        setType(5);
        break;
      case translate("BRK"):
        console.log(6)
        setType(6);
        break;
      case translate("Max speed"):
        console.log(7)
        setType(7);
        break;
      case translate("Duration"):
        console.log(8)
        setType(8);
        break;
      case translate("Start time"):
        console.log(9)
        setType(9);
        break;
      case translate("End time"):
        console.log(10)
        setType(10);
        break;
      case translate("Score"):
        console.log(11)
        setType(11);
        break;
      case translate("Rest time"):
        console.log(12)
        setType(12);
        break;
      case translate("Driving time"):
        console.log(13)
        setType(13);
        break;
      default:
        console.log('Unknown selection');
        console.log(selectedValue)
        break;
    }
    setTypeSearch(selectedValue);
    console.log('Selected value:', selectedValue);
  };


  const menuItems = [
    translate("Report ID"),
    translate("Driver name"),
    translate("iButton code"),
    translate("PSN"),
    translate("Distance"),
    translate("ACC"),
    translate("BRK"),
    translate("Max speed"),
    translate("Duration"),
    translate("Start time"),
    translate("End time"),
    translate("Score"),
    translate("Rest time"),
    translate("Driving time")
  ];


  const handleResetSearch = async () => {

    setSearch("")

    await getReport(limit, page, search, type, colum, sort)
  };


  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-6">
          <h6>
            <i className="las la-chart-bar" data-rel="bootstrap-tooltip" title="Increased"></i> {translate("Driver behavior")}  {' '}
            Nr.Tournée: {recapReport?.turn_report},  Du{' '}
            <span style={{ color: '#3468C0' }}>{toTimestamp(recapReport?.date_debut ?? '')}</span>
            {' '}  au {' '}
            <span style={{ color: '#3468C0' }}>{toTimestamp(recapReport?.date_fin ?? '')}</span>
          </h6>
        </div>
        <div className="col-sm-6 col-md-6 " >
          <div id="DataTables_Table_0_filter" className="float-right dataTables_filter mr-3">

            <a href="#" className="btn btn-outline-secondary  mr-1">
              <i className="las la-plus mr-3"></i>
              {translate('Import Report')}
            </a>
            <a href="#" className="btn btn-outline-info ">
              <i className="las la-plus mr-3"></i>
              {translate('Download')} XLSX
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="row m-2">
          <div className="col-sm-6 col-md-6" style={{ margin: "0px 0px 10px 0px", padding: "0px" }}>

            <div className="input-group">
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic" >
                  <i
                    className="fas fa-chevron-down"
                    style={{ fontSize: "20" }}
                  ></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {menuItems.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleTypeSearch(item)}
                      eventKey={item}
                      active={typeSearch === item}
                      className={typeSearch === item ? "select-active" : ""}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <input type="text" placeholder={` ${translate("Search by")} ${translate(typeSearch)}`} onChange={handleAdvancedSearch} className="form-control" />
              <Button
                variant="secondary"
                onClick={handleResetSearch}
                className="btn-reset"
              >
                <i className="las la-times" style={{ color: "#fff" }}></i>
              </Button>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label style={{ float: "right" }}>
                {translate('Show')}
                <select
                  name="DataTables_Table_0_length"
                  aria-controls="DataTables_Table_0"
                  className="custom-select custom-select-sm form-control form-control-sm mr-2 ml-2"
                  style={{ width: "66px" }}
                  onChange={handleSelectChange}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                </select>
              </label>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                title={translate("Display columns")}
              >
                <i className="las la-eye"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>

                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.driver_name}
                    onChange={() => handleColumnChange("driver_name")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Driver Name")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.ibutton_code}
                    onChange={() => handleColumnChange("ibutton_code")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("iButton Code")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.psn}
                    onChange={() => handleColumnChange("psn")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("PSN")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.distance}
                    onChange={() => handleColumnChange("distance")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Distance (Km)")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.acc}
                    onChange={() => handleColumnChange("acc")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Acceleration")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.brk}
                    onChange={() => handleColumnChange("brk")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Braking")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.max_speed}
                    onChange={() => handleColumnChange("max_speed")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Highest Speed (km/h)")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.duration}
                    onChange={() => handleColumnChange("duration")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Duration")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.start}
                    onChange={() => handleColumnChange("start")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Start Time")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.end}
                    onChange={() => handleColumnChange("end")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("End Time")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.score}
                    onChange={() => handleColumnChange("score")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Total Score")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.rest_time}
                    onChange={() => handleColumnChange("rest_time")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Rest Time")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={selectedColumns.driving_time}
                    onChange={() => handleColumnChange("driving_time")}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {translate("Driving Time")}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </div>

        </div>
        <div className="row m-1 table-responsive">
          <Table className="dataTable">
            <thead className="bg-white">
              <tr>
                {selectedColumns.id_repport && (
                  <th className="sorting" style={{ textAlign: 'left' }} onClick={() => handleSortingColum("id_repport")}>
                    {translate("Report ID")}
                  </th>
                )}
                {selectedColumns.ibutton_code && (


                  <th className="sorting" style={{ textAlign: 'left' }} onClick={() => handleSortingColum("ibutton_code")}>
                    {translate("Tag")}
                  </th>
                )}
                {selectedColumns.driver_name && (
                  <th className="sorting" style={{ textAlign: 'left' }} onClick={() => handleSortingColum("driver_name")}>
                    {translate("Drivers")}
                  </th>
                )}
                {selectedColumns.psn && (
                  <th className="sorting" style={{ textAlign: 'left' }} onClick={() => handleSortingColum("psn")}>
                    {translate("Vehicles")}
                  </th>
                )}
                {selectedColumns.distance && (
                  <th className="sorting" style={{ textAlign: 'left' }} onClick={() => handleSortingColum("distance")}>
                    {translate("Distance")} (Km)
                  </th>
                )}
                {selectedColumns.acc && (
                  <th className="sorting" onClick={() => handleSortingColum("acc")}>
                    {translate("Acceleration")}
                  </th>
                )}
                {selectedColumns.brk && (
                  <th className="sorting" onClick={() => handleSortingColum("brk")}>
                    {translate("Braking")}
                  </th>
                )}
                {selectedColumns.max_speed && (
                  <th className="sorting" onClick={() => handleSortingColum("max_speed")}>
                    {translate("Highest speed (km/h)")}
                  </th>
                )}
                {selectedColumns.max_speed && (
                  <th className="sorting" colSpan={2} style={{ textAlign: 'center' }} onClick={() => handleSortingColum("max_speed")}>
                    {translate("Speeding violation")}
                  </th>
                )}
                {selectedColumns.duration && (
                  <th className="sorting" colSpan={3} style={{ textAlign: 'center' }} onClick={() => handleSortingColum("duration")}>
                    {translate("Exceeding Driving Hours [HOS]")}
                  </th>
                )}
                {selectedColumns.driving_time && (
                  <th className="sorting" style={{ textAlign: 'left' }} onClick={() => handleSortingColum("driving_time")}>
                    {translate("Total score")}
                  </th>
                )}

              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style={{ textAlign: 'left' }}>Penalty</th>
                <th style={{ textAlign: 'left' }}>Speed</th>
                <th style={{ textAlign: 'left' }}>Penalty</th>
                <th style={{ textAlign: 'left' }}>Driving </th>
                <th style={{ textAlign: 'left' }}>Rest</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr style={{ textAlign: "center" }}>
                  <td className="text-center" colSpan={10}>
                    <p>
                      <PropagateLoader color={"#123abc"} loading={loading} size={20} />
                    </p>
                  </td>
                </tr>
              ) : Reports.length > 0 ? (
                Reports.map((data: ReportData) => {
                  return (
                    <tr key={data.id_repport} role="row">
                      {selectedColumns.id_repport && <td>{data.id_repport}</td>}
                      {selectedColumns.ibutton_code &&
                        <td
                          id={`tag-${data.ibutton_code}`}
                          onClick={() => copyToClipboard(data.ibutton_code)}
                          title="Cliquez pour copier le tag"
                          style={{ cursor: 'pointer' }}
                        >
                          {data.ibutton_code && (
                            <>
                              <i className="las la-tags" style={{ marginRight: '4px' }}></i>
                              <span style={{ color: '#007bff' }}>{data.ibutton_code}</span>
                            </>
                          )}
                      </td>}
                      {selectedColumns.driver_name && <td>{data.driver_name}</td>}
                      {selectedColumns.psn && <td>{data.psn}</td>}
                      {selectedColumns.distance && <td>{data.distance}</td>}
                      {selectedColumns.acc && <td>{data.acc}</td>}
                      {selectedColumns.brk && <td>{data.brk}</td>}
                      {selectedColumns.max_speed && <td>{data.max_speed}</td>}
                      {selectedColumns.max_speed && <td>{data.max_speed}</td>}
                      {selectedColumns.duration && <td>{data.duration}</td>}
                      {selectedColumns.driving_time && <td> {calculateDrivingTime(data.driving_time, 6).hosPenalty}</td>}
                      {selectedColumns.driving_time && <td>{calculateDrivingTime(data.driving_time, 6).drivingExceeded} </td>}
                      {selectedColumns.rest_time && <td>{calculateRestTime(data.rest_time, 6).restExceeded}</td>}
                      <td className={getScoreClass(data.score)}>{data.score.toFixed(2)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>No report available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {Reports.length} {translate("on")}{" "}
            {total}
          </span>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  );
}

export default Report35;
