import React from 'react';
import ChatSidebarLeft from '../components/Chat/ChatSidebarLeft';
import ChatContacts from '../components/Chat/ChatContacts';
import ChatHistory from '../components/Chat/ChatHistory';
import ChatSidebarRight from '../components/Chat/ChatSidebarRight';

const Chat: React.FC = () => {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="app-chat card overflow-hidden"> 
        <div className="row g-0">
          <ChatContacts />
          <ChatHistory />
          <ChatSidebarRight /> 
        </div>
      </div>
    </div>
  );
}; 

export default Chat;
