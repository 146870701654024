import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Bounce, toast } from 'react-toastify';
import { useTranslate } from "../../LanguageProvider";
import SelectUsers from "./SelectUsers";
import User from "../../../pages/User";


const backendUrl = process.env.REACT_APP_BACKEND_URL;

interface AlarmGrouptModalProps {
  show: boolean;
  onHide: () => void;
  refreshAlarmGroupts: () => void;
  groupId: number; // 0 pour création, >0 pour mise à jour
}

const AlarmGrouptModal: React.FC<AlarmGrouptModalProps> = ({ show, onHide, refreshAlarmGroupts, groupId }) => {
  const userID = localStorage.getItem("userID");
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useTranslate();

  const [groupInfo, setGroupInfo] = useState({
    name: "",
    description: "",
    notifyEmail: false,
    notifyApp: false,
    notifyPopup: false,
    notifyReports: false,
    selectedUsers: [] as number[], // Utilisateurs sélectionnés
  });

  const [usersOptions, setUsersOptions] = useState<{ value: number; label: string }[]>([]);
  const [error, setError] = useState<string>("");

  interface CheckboxItem {
    id: string;
    name: string;
    value: string;
    label: string;
    disabled: boolean;
  }

  interface CheckboxListProps {
    items: CheckboxItem[];
  }


  const checkboxItems: CheckboxItem[] = [
    {
      id: 'nfy_popup_cb_0',
      name: 'notify_popup',
      value: '1',
      label: 'Afficher les fenêtres contextuelles dʼalarme',
      disabled: false,
    },
    {
      id: 'nfy_reports_cb_0',
      name: 'notify_reports',
      value: '1',
      label: 'Autoriser lʼalarme dans les rapports',
      disabled: false,
    },
  ];

  useEffect(() => {
    if (show) {
      if (userID) {
        getUser(userID);
      }

      if (groupId > 0) {
        const loadGroupData = async () => {
          setLoading(true);
          try {
            const response = await axios.get(`${backendUrl}/api/alarm/group/find/${groupId}`);
            if (response.status === 200) {
              setGroupInfo({
                name: response.data.name_groupe_alarme,
                description: response.data.description,
                notifyEmail: response.data.avertir_par_mail,
                notifyApp: response.data.avertir_user_app,
                notifyPopup: response.data.notifyPopup || false,
                notifyReports: response.data.notifyReports || false,
                selectedUsers: response.data.selectedUsers || [], // Charger les utilisateurs sélectionnés
              });
            } else {
              setError("Failed to load group data");
            }
          } catch (error: any) {
            if (error.response) {
              console.error("Error loading group data:", error.response.data);
              setError("Error loading group data. Please try again.");
            } else {
              console.error("Error loading group data:", error.message);
              setError("An error occurred while loading the group data. Please try again.");
            }
          } finally {
            setLoading(false);
          }
        };

        loadGroupData();
      } else {
        resetForm();
      }
    }
  }, [show, groupId]);

  const resetForm = () => {
    setGroupInfo({
      name: "",
      description: "",
      notifyEmail: false,
      notifyApp: false,
      notifyPopup: false,
      notifyReports: false,
      selectedUsers: [],
    });
    setError("");
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      let response;
      if (groupId > 0) {
        response = await axios.put(`${backendUrl}/api/alarm/group/update/${groupId}`, {
          id_user: userID,
          name_groupe_alarme: groupInfo.name,
          description: groupInfo.description,
          avertir_par_mail: groupInfo.notifyEmail,
          avertir_user_app: groupInfo.notifyApp,
          notifyPopup: groupInfo.notifyPopup,
          notifyReports: groupInfo.notifyReports,
          selectedUsers: groupInfo.selectedUsers,
        });
      } else {
        response = await axios.post(`${backendUrl}/api/alarm/group/create`, {
          id_user: userID,
          name_groupe_alarme: groupInfo.name,
          description: groupInfo.description,
          avertir_par_mail: groupInfo.notifyEmail,
          avertir_user_app: groupInfo.notifyApp,
          notifyPopup: groupInfo.notifyPopup,
          notifyReports: groupInfo.notifyReports,
          selectedUsers: groupInfo.selectedUsers,
        });
      }

      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        toast.success(translate("Group created successfully"), {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        resetForm();
        onHide();
        refreshAlarmGroupts();
      } else {
        setError("Failed to create group");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        console.error("Error creating group:", error.response.data);
        setError("Error creating group. Please try again.");
      } else if (error.request) {
        console.error("Request error creating group:", error.request);
        setError("Error creating group. Please try again.");
      } else {
        console.error("Error creating group:", error.message);
        setError("An error occurred while creating the group. Please try again.");
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = event.target;
    const checked = (event.target as HTMLInputElement).checked;

    setGroupInfo((prevGroupInfo) => ({
      ...prevGroupInfo,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleUserChange = (selectedOptions: { value: number; label: string }[] | null) => {
    if (Array.isArray(selectedOptions)) {
      const selectedUserIds = selectedOptions.map(option => option.value);
      setGroupInfo(prevGroupInfo => ({
        ...prevGroupInfo,
        selectedUsers: selectedUserIds,
      }));
    } else {
      setGroupInfo(prevGroupInfo => ({
        ...prevGroupInfo,
        selectedUsers: [],
      }));
    }
  };


  const getUser = async (userId: any) => {
    try {
      const res = await fetch(`${backendUrl}/api/users/find/${userId}`, {
        mode: "cors",
      });

      if (!res.ok) {
        console.error("Erreur lors de la récupération des utilisateurs");
        return;
      }

      const usersData = await res.json();
      setUsers(usersData);

      const usersOptionsData = usersData.map((user: any) => ({
        value: user.id_user,
        label: `${user.nom_user} ${user.prenom_user || ""}`,
      }));

      setUsersOptions(usersOptionsData);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs", error);
    }
  };

  interface UserInterface {
    id_user: number;
    nom_user: string;
    prenom_user: string;
    email_user: string;
  }

  const [Users, setUsers] = useState<UserInterface[]>([]);

  type Email = {
    id_user: number;
    email: string;
  };

  type User = {
    id_user: number;
    name: string;
  };

  const [selectedEmailList, setSelectedEmailList] = useState<Email[]>([]);
  const [selectedUserList, setSelectedUserList] = useState<User[]>([]);

  const loadInitialData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/alarm/group/${groupId}`);
      const groupData = response.data;
      // Supposons que `groupData` contient `emails` et `users` en plus des autres informations du groupe.
      setSelectedEmailList(groupData.emails);
      setSelectedUserList(groupData.users);
      // Autres initialisations...
    } catch (error) {
      console.error("Error loading group data:", error);
    }
  };



  const updateGroupAlarm = async () => {
    try {
      setLoading(true);

      // Extraire les IDs des emails et des utilisateurs
      const emailIds = selectedEmailList.map(email => email.id_user);
      const userIds = selectedUserList.map(user => user.id_user);

      // Préparer les données pour la requête PUT
      const requestData = {
        id_user: userID,
        name_groupe_alarme: groupInfo.name,
        description: groupInfo.description,
        avertir_par_mail: emailIds,  // Liste des IDs d'emails
        avertir_user_app: userIds,   // Liste des IDs d'utilisateurs
        notifyPopup: groupInfo.notifyPopup,
        notifyReports: groupInfo.notifyReports,
      };

      const response = await axios.put(`${backendUrl}/api/alarm/group/update/${groupId}`, requestData);

      if (response.status === 200 || response.status === 204) {
        toast.success(translate("Group updated successfully"), {
          position: "bottom-right",
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        resetForm();
        onHide();
        refreshAlarmGroupts();
      } else {
        setError("Failed to update group");
      }
    } catch (error: any) {
      if (error.response) {
        console.error("Error updating group:", error.response.data);
        setError("Error updating group. Please try again.");
      } else if (error.request) {
        console.error("Request error updating group:", error.request);
        setError("Error updating group. Please try again.");
      } else {
        console.error("Error updating group:", error.message);
        setError("An error occurred while updating the group. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{groupId > 0 ? "Modifier un groupe" : "Ajouter un groupe"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row" style={{ marginTop: 10 }}>
            <Form.Group className="col-md-2 col-sm-2">
              <Form.Label>Nom du groupe</Form.Label>
            </Form.Group>
            <Form.Group className="col-md-10 col-sm-10">
              <Form.Control
                type="text"
                name="name"
                value={groupInfo.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <Form.Group className="col-md-2 col-sm-2">
              <Form.Label>Description</Form.Label>
            </Form.Group>
            <Form.Group className="col-md-10 col-sm-10">
              <Form.Control
                as="textarea"
                name="description"
                value={groupInfo.description}
                onChange={handleChange}
                rows={3}
              />
            </Form.Group>
          </div>
          <div className="row" style={{ marginTop: 10 }}>



            <Form.Group className="col-md-2 col-sm-2">
              <Form.Label>Méthodes de notification</Form.Label>
            </Form.Group>

            <Form.Group className="row col-md-10 col-sm-10">

              <div className="row">

                <div className="col-6">
                  <div className="checkbox">
                    <Form.Check
                      id="nfy_email_cb_0"
                      name="notify_email"
                      type="checkbox"
                      value="email"
                      // disabled
                      label="Avertir par e-mail"
                      style={{ marginRight: '5px' }}
                    />
                  </div>
                  <ul
                    className="email-list"
                    style={{
                      border: '1px solid rgb(204, 204, 204)',
                      borderRadius: '3px',
                      marginTop: '10px',
                      padding: '5px',
                      minHeight: '60px',
                      maxHeight: '100px',
                      overflowY: 'auto', // Ajout d'un défilement si nécessaire
                    }}
                  >
                    <li style={{ listStyle: 'none' }}>

                      {Users && Users.length > 0 ? (
                        Users.filter(item => item.email_user !== "").map(item => (
                          <Form.Group key={item.id_user} className="checkbox">
                            <Form.Check
                              type="checkbox"
                              id={item.id_user.toString()}
                              name={item.email_user}
                              value={item.email_user}
                              disabled={false}
                              label={item.email_user}
                              style={{ marginLeft: '10px', marginRight: '5px' }}
                            />
                          </Form.Group>
                        ))
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className="fas fa-exclamation-circle" style={{ verticalAlign: 'top', paddingTop: '3px' }}></i>
                          <span style={{ display: 'inline-block', width: '90%', marginLeft: '5px', padding: '0px' }}>
                            Aucune adresse e-mail vérifiée
                          </span>
                        </div>
                      )}

                    </li>
                  </ul>
                </div>

                {/* Deuxième colonne */}
                <div className="col-6" style={{ marginTop: '30px' }}>
                  <div>
                    <div className="checkbox">
                      <Form.Check
                        id="nfy_email_in_rb_0"
                        type="radio"
                        name="notify_email_opt"
                        value="email_in"
                        // disabled
                        label="Début de lʼalarme uniquement"
                        style={{ marginLeft: '10px', marginRight: '5px' }}
                      />
                    </div>
                    <div className="checkbox">
                      <Form.Check
                        id="nfy_email_io_rb_0"
                        type="radio"
                        name="notify_email_opt"
                        value="email"
                        //  disabled
                        label="Début et fin de lʼalarme"
                        style={{ marginLeft: '10px', marginRight: '5px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6" >

                <div className="checkbox" >
                  <input type="checkbox" name="notify_app" value="app" />
                  <label style={{ paddingLeft: "5px" }}>Avertir les utilisateurs de lʼapplication</label>
                </div>

                <SelectUsers
                  controlId="user"
                  name="user"
                  label={translate("User")}
                  icon="user"
                  options={usersOptions}
                  valueType={usersOptions.filter(option => groupInfo.selectedUsers.includes(option.value))}
                  onChange={handleUserChange}
                  onClick={handleUserChange}
                  isMulti
                  placeholder="Sélectionnez des utilisateurs"
                />

              </div>

              <div className="col-6" style={{ marginTop: '40px' }}>
                {checkboxItems.map(item => (
                  <Form.Group key={item.id} className="checkbox">
                    <Form.Check
                      type="checkbox"
                      id={item.id}
                      name={item.name}
                      value={item.value}
                      disabled={item.disabled}
                      label={item.label}
                      style={{ marginLeft: '10px', marginRight: '5px' }}
                    />
                  </Form.Group>
                ))}
              </div>

            </Form.Group>
          </div>

          {error && <div className="text-danger">{error}</div>}
          <div className="text-center" style={{ marginTop: 20 }}>
            <Button variant="secondary" onClick={onHide} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Annuler"}
            </Button>
            <Button type="submit" variant="primary" style={{ marginLeft: 10 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : groupId > 0 ? "Modifier" : "Ajouter"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AlarmGrouptModal;
