import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, FeatureGroup, FeatureGroupProps, useMapEvents, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl-leaflet';
import { useTranslate } from '../../components/LanguageProvider';
import IconMarker from '../../components/Map/IconItinerary';
import { Col, Row } from 'react-bootstrap';
import '../../components/Map/map.css';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { engineStat, engineStatClass, toTimestamp } from '../../utilities/functions';
import { useSpring, animated } from 'react-spring';
import { LatLngBounds, LatLngBoundsExpression, LatLngBoundsLiteral, LatLngExpression, LatLngLiteral } from 'leaflet';
import L from 'leaflet';
import PopupItinerary from '../Map/PopupItinerary';


const backendUrl = process.env.REACT_APP_BACKEND_URL;

interface VehicleWidgetProps {
  type_report?: string;
  turn?: string;
  id_report?: string;
}

const Report1: React.FC<VehicleWidgetProps> = ({ type_report, turn, id_report, }) => {

  interface itinerey {
    id: number;
    start: string;
    end: string;
    name: string;
    distance: number;
    ALT: number;
    LAT: number;
    LNG: number;
    COG: number;
    SOG: number;
    ALARMS: string;
    PRIVATE_MODE: number;
  }

  const center: [number, number] = [30.5670197, 4.6911217];
  const zoom: number = 5;
  const MaxZoom: number = 18;
  const MinZoom: number = 5;
  const { translate } = useTranslate();
  const userID = localStorage.getItem('userID');
  const [itinereys, setItinereys] = useState<itinerey[]>([]);
  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [baseMap, setBaseMap] = useState<string>('google_roadmap');
  const [listLayout, setlistLayout] = useState('');
  const [mapLayout, setMapLayout] = useState('col-9');
  const [clicked, setClicked] = useState(false);
  const [ungroupMarker, setUngroupMarker] = useState<boolean>(true);
  const [selectedDayMarkers, setSelectedDayMarkers] = useState<MarkerData[]>([]);

  const mapRef = useRef<any>(null);
  const MapEventHandler: React.FC<{
    onMapReady: (mapInstance: any) => void
  }> = ({ onMapReady }) => {
    const map = useMap(); useEffect(() => {
      if (map) {
        onMapReady(map);
        // Appeler la fonction lorsque la carte est prête   
      }
    }, [map]); return null;
  };

  interface toChartData {
    x: number;
    y: number;
  }

  // Remplacez la déclaration de GroupComponent par ceci 
  var GroupComponent: React.ComponentType<any> = ungroupMarker
    ? MarkerClusterGroup
    : FeatureGroup;

  interface Vehicle {
    id_vehicule: number;
    immatriculation_vehicule: string;
    LAT: number;
    LNG: number;
    SOG: number;
    COG: number;
    ENGINESTAT: number;
    TIMESTAMP: string;
    GPSDIST: number;
    PSN: string;
  }


  // Définir l'animation de clic avec react-spring
  const animationProps = useSpring({
    transform: clicked ? 'scale(1.1)' : 'scale(1)',
    overflow: 'hidden',
    marginTop: clicked ? "55px" : "10px"
  });

  function ToUTCTime(isoDateString: string): number {
    if (!isoDateString) {
      return 0; // Ou une valeur par défaut appropriée
    }

    const dateComponents = isoDateString.split(/[-T:.Z]/);
    const start_date = dateComponents.map((component) =>
      parseInt(component, 10)
    );

    return Date.UTC(
      start_date[0],
      start_date[1] - 1,
      start_date[2],
      start_date[3],
      start_date[4],
      start_date[5],
      0
    );
  }


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };



  interface MarkerData {
    LAT: number;
    LNG: number;
  }

  interface MapContentProps {
    markers: MarkerData[];
  }


  // Définir un tableau de couleurs pour chaque jour de la semaine
  const colors = ['#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399', '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933', '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

  const flyToMarkers = (map: L.Map, markers: MarkerData[]) => {
    if (markers.length === 0 || !map) return;

    // Créer un tableau d'objets LatLngLiteral pour les marqueurs
    const markerLatLngs: LatLngLiteral[] = markers.map(marker => ({ lat: marker.LAT, lng: marker.LNG }));

    // Calculer les limites des marqueurs
    const bounds: LatLngBoundsLiteral = calculateBounds(markerLatLngs);

    // Adapter la vue de la carte pour inclure les limites des marqueurs
    map.fitBounds(bounds);

    // Dessiner des polygones pour chaque journée 
    for (const day in itinereysByDay) {
      console.log(parseInt(day) % colors.length); 
      console.log(getDay(dateEnd)-getDay(dateStart));  
      
      const color = colors[getDay(dateEnd)-getDay(day)];    
      const dayItineraries = itinereysByDay[day];
      const positions: LatLngExpression[] = dayItineraries.map((itinerey) => [itinerey.LAT, itinerey.LNG]);

      const polygon = L.polyline(positions, { color: color }).addTo(map);
    }
  };

  // Fonction pour calculer les limites à partir des coordonnées des marqueurs
  const calculateBounds = (markerLatLngs: LatLngLiteral[]): LatLngBoundsLiteral => {
    let minLat = Number.POSITIVE_INFINITY;
    let maxLat = Number.NEGATIVE_INFINITY;
    let minLng = Number.POSITIVE_INFINITY;
    let maxLng = Number.NEGATIVE_INFINITY;

    for (const { lat, lng } of markerLatLngs) {
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    }

    return [[minLat, minLng], [maxLat, maxLng]];
  };



  const MapCenter: React.FC<{ markers: MarkerData[] }> = ({ markers }) => {
    const map = useMap();

    useEffect(() => {
      if (markers.length > 0 && map) {
        console.log('Centrage de la carte sur les marqueurs');
        flyToMarkers(map, markers);
      }
    }, [markers, map]);

    return null;
  };


  const MapContent: React.FC = () => {
    const map = useMap();

    useEffect(() => {
      if (selectedItem) {
        console.log(selectedItem.id_vehicule);

        const { LAT, LNG } = selectedItem;

        map.flyTo([LAT, LNG], 15, { 
          // animate: false,
          duration: 1,
        });
      }
    }, [map, selectedItem]);

    return null;
  };


  const [selectedItem, setSelectedItem] = useState<Vehicle | null>(null);


  const basemapOptions = [
    { value: 'google_roadmap', label: 'Google Roadmap', icon: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}' },
    { value: 'osm', label: 'OSM', icon: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' },
    { value: 'hot', label: 'OSM HOT', icon: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png' },
    { value: 'dark', label: 'DARK', icon: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png' },
    { value: 'cycle', label: 'CYCLE MAP', icon: 'https://dev.{s}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png' },
    { value: 'google_traffic', label: 'Google Traffic', icon: 'https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}' },
    { value: 'google_satellite', label: 'Google Satellite', icon: 'https://www.google.com/maps/vt?lyrs=s@189&gl=us&x={x}&y={y}&z={z}' },
    { value: 'google_terrain', label: 'Google Terrain', icon: 'https://www.google.com/maps/vt?lyrs=p@189&gl=us&x={x}&y={y}&z={z}' },
    //{ value: 'mapbox', label: 'Mapbox', icon: 'https://api.mapbox.com/styles/v1/idenet/ckoytzfxw0mr417mju7fh5kc5.html?title=view&access_token=pk.eyJ1IjoiaWRlbmV0IiwiYSI6ImNqeGs0dHAzbTFlb3UzeXFkOWk1ZWQ5cDIifQ.lpPl03eDGutSjsd0fLaPkw&zoomwheel=true&fresh=true#9/37.78/-122.4241' },
  ];

  const [itinereysByDay, setItinereysByDay] = useState<{ [day: string]: itinerey[] }>({});
  const [selectedDay, setSelectedDay] = useState<string | null>(null);


  useEffect(() => {
    const getItinereys = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/report1/${id_report}`, { mode: "cors" });
        if (response.ok) {
          const data = await response.json();
          const itinereys = data["repportDatas"];
          setItinereys(itinereys)
          
          setDateStart( toTimestamp( itinereys[0].start).split(' ')[0]);
          setDateEnd( toTimestamp( itinereys[itinereys.length-1].start).split(' ')[0])

          // Créer un objet pour stocker les itinéraires par jour
          const itinereysByDayObj: { [day: string]: itinerey[] } = {};

          // Remplir l'objet avec les itinéraires correspondants à chaque jour
          itinereys.forEach((itinerey: itinerey) => {
            const day = toTimestamp(itinerey.start).split(' ')[0];
            if (!itinereysByDayObj[day]) {
              itinereysByDayObj[day] = [];
            }
            itinereysByDayObj[day].push(itinerey);
          });

          setItinereysByDay(itinereysByDayObj);

          // Définir la première journée comme sélectionnée
          const firstDay = Object.keys(itinereysByDayObj)[0];
          setSelectedDay(firstDay);



        } else {
          console.error('Failed to fetch vehicle data');
        }
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      }
    };

    getItinereys();
  }, [id_report])

  useEffect(() => {
    // Mettre à jour les marqueurs de la journée sélectionnée
    const markers = selectedDay ? itinereysByDay[selectedDay]?.map(itinerey => ({
      LAT: itinerey.LAT,
      LNG: itinerey.LNG // Utiliser LNG au lieu de LNG, si c'est ce que vous utilisez dans votre type MarkerData
    })) || [] : [];
    console.log("Selected day markers updated:", markers); //
    setSelectedDayMarkers(markers);
  }, [selectedDay, itinereysByDay]);


  // Fonction pour calculer la différence en heures entre deux timestamps
  function calculateHoursDifference(
    timestamp1: string,
    timestamp2: string
  ): number {
    const diffInMilliseconds =
      new Date(timestamp1).getTime() - new Date(timestamp2).getTime();
    return diffInMilliseconds / (1000 * 60 * 60);
  }

  const handleItemClick = (item: itinerey) => {
    console.log(item);

  };

  const handleGroupMarker = () => {
    ungroupMarker == false ? setUngroupMarker(true) : setUngroupMarker(false)
  };


  interface BasemapsDict {
    google_roadmap: string;
    osm: string;
    hot: string;
    dark: string;
    cycle: string;
    google_traffic: string;
    google_satellite: string;
    google_terrain: string;
    [key: string]: string;
  }

  const basemapsDict: BasemapsDict = {
    google_roadmap: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
    osm: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    hot: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    dark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png',
    cycle: 'https://dev.{s}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
    google_traffic: 'https://mt0.google.com/vt?lyrs=h@221097413,traffic&x={x}&y={y}&z={z}',
    google_satellite: 'https://www.google.com/maps/vt?lyrs=s@189&gl=us&x={x}&y={y}&z={z}',
    google_terrain: 'https://www.google.com/maps/vt?lyrs=p@189&gl=us&x={x}&y={y}&z={z}',
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleWheelScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
    console.log("Wheel event triggered");
    const container = containerRef.current;
    if (container) {
      container.scrollTop += event.deltaY;
    }
  };

  const mapLayoutClick = () => {
    listLayout == "layoutfloat" ? setlistLayout("layoutleft") : setlistLayout("layoutfloat");
    mapLayout == "col-12" ? setMapLayout("col-9") : setMapLayout("col-12");
    console.log('Clic sur map-layout');
  };


  const [vehicleState, setVehicleState] = useState<string | null>(null);

  const handleMarker = (state: string) => {
    if (vehicleState === state) {
      setVehicleState(null); // If the filter is already selected, clear the filter
    } else {
      setVehicleState(state); // Otherwise, apply the filter
    }
  };

  const timeZone = (datetime: string) => {
    const originalDate = new Date(datetime);
    const newDate = new Date(originalDate.getTime());

    // Ajouter une heure pour l'heure d'été
    newDate.setHours(newDate.getHours() + 1);

    return newDate.toLocaleString(); // Convertir en chaîne de caractères pour l'affichage
  };


 

  /*****************************************Last Update */

  const [expandedDays, setExpandedDays] = useState<string[]>([]);

  const toggleAllDay = (day: string) => {
    setSelectedDay(day);
    setExpandedDays((prevExpandedDays) => {
      if (prevExpandedDays.includes(day)) {
        return prevExpandedDays.filter((d) => d !== day);
      } else {
        return [...prevExpandedDays, day];
      }
    });
  };

  const checkDay = (day: string) => {
    // Your logic for checking day
  };

  const checkTime = (index: number) => {
    // Your logic for checking time
  };

 const goToPosition = (lat: number, lng: number) => { 
 
  const map = mapRef.current; // Accéder à l'instance de la carte
 // Réinitialiser l'interaction utilisateur

  // Centrer la carte sur le véhicule sélectionné
  map.flyTo([lat, lng], 17, { duration: 1 });


};
  interface MarkerData {
    LAT: number;
    LNG: number;
  }

  const getDay = (dateStr: string): number => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0'); // Ajouter un 0 devant les jours de 1 à 9
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont de 0 à 11
    const year = date.getFullYear();
    return parseInt(day); // Format: jj/mm/aaaa
  };

  const renderList = (itinerey: itinerey, itinereys: itinerey[],day:string,isExpanded:boolean,state:string,i:number) => {
    if (!itinerey) {
      return null;
    }



    if (itinerey.name === 'Moteur arrêté') {
      return (
        <>
          <div className={`trips_${day} ${isExpanded ? 'show' : 'hide'}`} style={{ paddingLeft: '16px' }}>
            <div id={`trip_${i}`} className={state} style={{ minWidth: '100%', float: 'left', display: itinerey.name === 'Moteur arrêté' && !isExpanded ? 'none' : 'block' }}>
              <div className="checkbox" style={{ float: 'left' }}>
                <input type="checkbox" className={`tchk_${day} tchk_${i}`} onClick={() => checkTime(i)} name={`tchk_${day}`} value={day} checked />
                <label htmlFor={`tchk_${day}`}>  </label>
                <a onClick={() => goToPosition(itinerey.LAT, itinerey.LNG)} style={{ marginLeft: "8px", cursor: 'pointer', textDecoration: 'none' }} className="marker">
                  <i className="fa fa-pause" style={{ color: 'blue' }}></i> {toTimestamp(itinerey.start).split(' ')[1]} - {itinerey.end.split(' ')[1]}
                  <span style={{ marginLeft: '3px', color: '#000', fontSize: '0.9em' }}>
                    {`${((itinerey.distance - itinereys[0].distance) / 1000).toFixed(2)} Km`}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </>
      );
    } else if (itinerey.name === "Moteur démarré" && itinerey.SOG < 5) {
      return (
        <>
          <div className={`trips_${day} ${isExpanded ? 'show' : 'hide'}`} style={{ paddingLeft: '16px' }}> 
            <div id={`trip_${i}`} className={state} style={{ minWidth: '100%', float: 'left', display:( itinerey.name === "Moteur démarré" && itinerey.SOG < 5 )&& !isExpanded ? 'none' : 'block' }}>
              <div className="checkbox" style={{ float: 'left' }}>
                <input type="checkbox" className={`tchk_${day} tchk_${i}`} onClick={() => checkTime(i)} name={`tchk_${day}`} value={day} checked />
                <label htmlFor={`tchk_${day}`}>  </label>
                <a onClick={() => goToPosition(itinerey.LAT, itinerey.LNG)} style={{ marginLeft: "8px", cursor: 'pointer', textDecoration: 'none' }} className="marker">
                  <i className="fa fa-stop" style={{ color: 'red' }}></i> {toTimestamp(itinerey.start).split(' ')[1]} - {itinerey.end.split(' ')[1]}
                  <span style={{ marginLeft: '3px', color: '#000', fontSize: '0.9em' }}>
                    {`${((itinerey.distance - itinereys[0].distance) / 1000).toFixed(2)} Km`}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </>
      );
    }

    return null;
  };


  return (
    <>
      <Row>
        <div className={`${listLayout} col-12 col-md-3`} style={{ zIndex: 9999, paddingLeft: '30px', margin: ' 20px 0' }}>
          <div style={{ margin: "0px 0 15px 0" }} className="">
            <button type="button" data-toggle="tooltip" className={`btn btn-default btn-sm pull-left act ${vehicleState !== "move" ? "active" : ""}`} id="act_moving" onClick={() => handleMarker("move")} title="Engine running">

              <i className="la la-play-circle" style={{ color: 'green', fontSize: "20px" }}></i>
            </button>
            <button type="button" data-toggle="tooltip" className={`btn btn-delault btn-sm pull-left act ${vehicleState !== "pause" ? "active" : ""}`} id="act_pause" onClick={() => handleMarker("pause")} title="Parking with engine started">

              <i className="la la-pause-circle" style={{ color: 'red', fontSize: "20px" }}></i>
            </button>
            <button type="button" data-toggle="tooltip" className={`btn btn-delault btn-sm pull-left act ${vehicleState !== "stop" ? "active" : ""}`} id="act_stoping" onClick={() => handleMarker("stop")} title="Parking">
              <i className="las la-stop-circle" style={{ color: 'blue', fontSize: "20px" }}></i>
            </button>
            <button type="button" data-toggle="tooltip" className="btn btn-delault btn-sm pull-left act" id="act_regrouper" onClick={() => handleGroupMarker()} title="Group or ungroup vehicles">
              <i className="la la-object-ungroup" style={{ color: '#000', fontSize: "20px" }}> </i>
            </button>
            {/* <span className='map-layout' onClick={mapLayoutClick}>
              <i className="las la-list" data-rel="bootstrap-tooltip" title="Increased" style={{ fontSize: "24px" }}></i>
            </span> */}
          </div>

          <div className="well well-sm" id="track_dates" style={{ background: "#fff", marginTop: '5px', padding: '3px', overflowY: 'scroll', minHeight: '140px', height: '664px' }} data-dist-src="gps">
            {itinereys.map((itinerey, i) => {
              const day = toTimestamp(itinerey.start).split(' ')[0];
              const state = itinerey.name === 'Moteur arrêté' ? 'stop' : 'move';
              const isExpanded = expandedDays.includes(day);

              return (
                <React.Fragment key={i}>
                  {i === 0 || day !== toTimestamp(itinereys[i - 1].start).split(' ')[0] ? (
                    <div id={day} style={{ display: 'inline-block', minWidth: '100%' }}>
                      <div className="checkbox" style={{ float: 'left' }}>
                        <i className="fa fa-fw fa-caret-right" style={{ visibility: 'visible' }} onClick={() => toggleAllDay(day)}></i>
                        <input type="checkbox" onClick={() => checkDay(day)} value={day} checked />
                        <label htmlFor={`chk_${day}`}></label>
                        <a id={`day_${day}`} onClick={() => toggleAllDay(day)} style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: '3px', fontWeight: 'bold' }}>{day}</a>
                      </div>
                    </div>
                  ) : null}
                   {renderList(itinerey, itinereys,day,isExpanded,state,i)} 
                </React.Fragment>
              );
            })}

          </div>
        </div>
        <div className={mapLayout} style={{ padding: "0px" }}>
          <MapContainer center={center} zoom={zoom} minZoom={MinZoom} maxZoom={MaxZoom} style={{ padding: 0, margin: 0, height: '86vh', width: '100%', zIndex: 1 }}>
            <MapContent />

            <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url={basemapsDict[baseMap]} />
            <div className="basemaps-container">
              <select
                value={baseMap}
                onChange={(e) => setBaseMap(e.target.value)}
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}asset/images/mapicon/layers.png)`,
                  appearance: 'none',
                  backgroundSize: '22px',
                  backgroundPosition: ' center center',
                  backgroundRepeat: 'no-repeat',
                  border: 'none',
                  color: 'transparent',
                  outline: 'none',
                }}
              >
                {basemapOptions.map((option) => (
                  <option key={option.value} value={option.value} style={{ color: 'black' }}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <GroupComponent>
              <div>
                {selectedDay && itinereysByDay[selectedDay] && (
                  <>
                    {itinereysByDay[selectedDay].map((item) => (
                      <Marker
                        key={item.id}
                        position={[item.LAT, item.LNG]}
                        icon={IconMarker(item.COG, item.SOG, item.name, () => handleItemClick(item))}
                      >
                        <PopupItinerary itinerary={item} />
                      </Marker>
                    ))}
                  </>
                )}
              </div>
            </GroupComponent>
            <FullscreenControl position="topright" title="Show me the fullscreen map" forceSeparateButton={false} />
            <MapCenter markers={selectedDayMarkers} />
            <MapEventHandler onMapReady={(mapInstance) => { mapRef.current = mapInstance; }} />
          </MapContainer>

        </div>

      </Row>

    </>
  );
};


export default Report1;
