import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerComponent from "./Marker";
import IconMarker from "./Icon";
import { useTranslate } from "../LanguageProvider";
import { getAddressFromCoordinates } from "../../utilities/functions"; // Importer la fonction

interface MapModalProps {
  show: boolean;
  onClose: () => void;
  lat: number;
  lng: number;
}
interface BasemapsDict {
  google_roadmap: string;
  osm: string;
  hot: string;
  dark: string;
  cycle: string;
  google_traffic: string;
  google_satellite: string;
  google_terrain: string;
  //  mapbox: string;
  [key: string]: string;
}

const MapModal: React.FC<MapModalProps> = ({
  show,
  onClose,
  lat,
  lng,
}) => {
  const position = [lat, lng] as [number, number];
  const { translate } = useTranslate();
  const [address, setAddress] = useState<string>(""); // État pour stocker l'adresse

  const [baseMap, setBaseMap] = useState<string>("google_roadmap");

  useEffect(() => {
    // Appeler la fonction getAddressFromCoordinates lorsque les coordonnées changent
    const fetchAddress = async () => {
      try {
        const address = await getAddressFromCoordinates(lat, lng);
        setAddress(address);
      } catch (error) {
        console.error("Failed to fetch address:", error);
        setAddress("Address not found");
      }
    };

    fetchAddress();
  }, [lat, lng]); // Exécuter l'effet à chaque changement de lat ou lng

  // Example custom icon creation
  const customIcon = IconMarker(90, "asset/images/mapicon/car.svg", () => {
    // onClick functionality
  });

  const basemapsDict: BasemapsDict = {
    google_roadmap: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    osm: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    hot: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    dark: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png",
    cycle: "https://dev.{s}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
    google_traffic:
      "https://mt0.google.com/vt?lyrs=h@221097413,traffic&x={x}&y={y}&z={z}",
    google_satellite:
      "https://www.google.com/maps/vt?lyrs=s@189&gl=us&x={x}&y={y}&z={z}",
    google_terrain:
      "https://www.google.com/maps/vt?lyrs=p@189&gl=us&x={x}&y={y}&z={z}",
    // mapbox: 'https://api.mapbox.com/styles/v1/idenet/ckoytzfxw0mr417mju7fh5kc5.html?title=view&access_token=pk.eyJ1IjoiaWRlbmV0IiwiYSI6ImNqeGs0dHAzbTFlb3UzeXFkOWk1ZWQ5cDIifQ.lpPl03eDGutSjsd0fLaPkw&zoomwheel=true&fresh=true#9/37.78/-122.4241',
  };

  const basemapOptions = [
    {
      value: "google_roadmap",
      label: "Google Roadmap",
      icon: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    },
    {
      value: "osm",
      label: "OSM",
      icon: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    {
      value: "hot",
      label: "OSM HOT",
      icon: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    },
    {
      value: "dark",
      label: "DARK",
      icon: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}@2x.png",
    },
    {
      value: "cycle",
      label: "CYCLE MAP",
      icon: "https://dev.{s}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
    },
    {
      value: "google_traffic",
      label: "Google Traffic",
      icon: "https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}",
    },
    {
      value: "google_satellite",
      label: "Google Satellite",
      icon: "https://www.google.com/maps/vt?lyrs=s@189&gl=us&x={x}&y={y}&z={z}",
    },
    {
      value: "google_terrain",
      label: "Google Terrain",
      icon: "https://www.google.com/maps/vt?lyrs=p@189&gl=us&x={x}&y={y}&z={z}",
    },
    //{ value: 'mapbox', label: 'Mapbox', icon: 'https://api.mapbox.com/styles/v1/idenet/ckoytzfxw0mr417mju7fh5kc5.html?title=view&access_token=pk.eyJ1IjoiaWRlbmV0IiwiYSI6ImNqeGs0dHAzbTFlb3UzeXFkOWk1ZWQ5cDIifQ.lpPl03eDGutSjsd0fLaPkw&zoomwheel=true&fresh=true#9/37.78/-122.4241' },
  ];

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{alignContent:"center"}}>{translate("Vehicle Position")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={basemapsDict[baseMap]}
          />
          <div
            className="basemaps-container"
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <select
              value={baseMap}
              onChange={(e) => setBaseMap(e.target.value)}
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}asset/images/mapicon/layers.png)`,
                appearance: "none",
                backgroundSize: "22px",
                backgroundPosition: " center center",
                backgroundRepeat: "no-repeat",
                border: "none",
                color: "transparent",
                outline: "none",
                zIndex: 1000, // Ensure it's above the map
              }}
            >
              {basemapOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  style={{ color: "black" }}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Use MarkerComponent with custom icon */}
          <MarkerComponent
            position={position}
            text={`position: ${lat},${lng}<br />adresse: ${address}`}
            icon={customIcon}
          />
        </MapContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {translate("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MapModal;
