import { divIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';


const IconMarker = (direction: number,img: string,onClick: () => void) => {  
 
  const iconStyle = {
    width: '15px',
    height: '15px',
    transform: `rotate(${direction}deg)`,
  };
 

  const htmlString = renderToString(<div style={iconStyle} >  { <img  src={img} onClick={onClick} />} </div>); 
 
  return divIcon({
    className: 'custom-icon',
    iconSize: [15, 15], 
    iconAnchor: [15, 15],
    popupAnchor: [0, -30],
    html: htmlString ,
  });
};

export default IconMarker;


