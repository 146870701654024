import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { Form, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslate } from "../components/LanguageProvider";
import CreateTicketModal from "../components/CreateTicket";
import { formatToTimestamp } from "../utilities/functions";

interface Ticket {
  id_ticket: number;
  jiraTicketId: number;
  creation_date: string;
  summary: string;
  description: string;
  issueType: string;
  priority: string;
  status: string;
  id_user: number;
  agent: number;
  category: number;
  sub_category: number;
  criticality: number;
}

export function Ticket() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { translate } = useTranslate();
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list_Ticket, setItems] = useState<Ticket[]>([]);

  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
  const handleShowCreateTicketModal = () => setShowCreateTicketModal(true);
  const handleCloseCreateTicketModal = () => setShowCreateTicketModal(false);

  const handleSubmit = () => {
    // Votre logique de soumission ici
  };

  const getTicket = async (currentPage: number, limit: number) => {
    try {
      const total_pages = await fetch(
        `${backendUrl}/api/ticket/totalpage/${1}`,
        { mode: "cors" }
      );
      const totalpages = await total_pages.json();
      const total = totalpages[0].total;
      setTotal(total);
      const calculatedPageCount = Math.ceil(total / limit);
      setPageCount(calculatedPageCount);

      const res = await fetch(
        `${backendUrl}/api/ticket/${1}/${currentPage}/${limit}`,
        { mode: "cors" }
      );
      const data = await res.json();
      setItems(data);
    } catch (error) {
      console.error("Erreur lors du chargement des Tickets  :", error);
    }
  };

  const refreshTickets = () => {
    getTicket(currentPage, limit);
  };

  const fetchTicket = async (currentPage: number, limit: number) => {
    const res = await fetch(
      `${backendUrl}/api/Ticket/${1}/${currentPage}/${limit}`,
      { mode: "cors" }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data: any) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchTicket(currentPage, limit);
    setItems(commentsFormServer);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getTicket(currentPage, limit);
  }, [currentPage, limit]);

  const [selectedColumns, setSelectedColumns] = useState({
    jiraTicketId: true,
    creation_date: true,
    summary: true,
    description: true,
    issueType: true,
    status: true,
    id_user: true,
    agent: true,
    category: true,
    sub_category: true,
    criticality: true,
  });

  const handleColumnChange = (column: string) => {
    setSelectedColumns((prevState: any) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h4>
            <i
              className="las la-ticket-alt"
              data-rel="bootstrap-tooltip"
              title="Ticket"
            ></i>{" "}
            {translate("Tickets")} ({total})
          </h4>
        </div>
        <div className="col-md-6 col-sm-12 text-right">
          <Button
            variant="primary"
            className="mt-2 mr-1"
            onClick={handleShowCreateTicketModal}
          >
            <i className="las la-plus mr-3"></i>Create Ticket
          </Button>
        </div>
      </div>
      <div className="row">
        <div    className="col-md-4"style={{ margin: "0px 0px 10px 0px", padding: "10px" }} >
          <div className="input-group">

            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i className="fas fa-chevron-down"   style={{fontSize:"20"}}></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>{translate("Ticket ID")}</Dropdown.Item>
                <Dropdown.Item>{translate("Agent")}</Dropdown.Item>
                <Dropdown.Item>{translate("Title")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <input type="text" placeholder="test" className="form-control" />

          </div>
        </div>
        <div className="col-md-8 d-flex justify-content-end align-items-center">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label style={{marginBottom: "0"}}>
              {translate("Show")}
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                style={{ width: "66px" }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </label>
          </div>
          <Dropdown>
            <Dropdown.Toggle  variant=""  id="dropdown-basic"  title="Colonnes dʼaffichage" >
              <i className="las la-list-ol"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as="button"   style={{ display: "flex", alignItems: "center" }} >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.jiraTicketId}
                  onChange={() => handleColumnChange("jiraTicketId")} 
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Ticket ID")}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.creation_date}
                  onChange={() => handleColumnChange("creation_date")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Checker")}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.summary}
                  onChange={() => handleColumnChange("summary")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Title")}</span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.issueType}
                  onChange={() => handleColumnChange("issueType")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Issue Type")}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.id_user}
                  onChange={() => handleColumnChange("id_user")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("User")}</span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.agent}
                  onChange={() => handleColumnChange("agent")}
                />
                <span style={{ marginLeft: "10px" }}>{translate("Agent")}</span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.category}
                  onChange={() => handleColumnChange("category")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Category")}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.sub_category}
                  onChange={() => handleColumnChange("sub_category")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate(" Sub category")}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.status}
                  onChange={() => handleColumnChange("status")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Status")}
                </span>
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedColumns.criticality}
                  onChange={() => handleColumnChange("criticality")}
                />
                <span style={{ marginLeft: "10px" }}>
                  {translate("Criticality")}
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <i onClick={handleShow} style={{fontSize:"20"}} className="las la-filter"> </i>

        </div>
      </div>
      <div className="row m-1">
        <Table>
          <thead className="bg-white text-uppercase">
            <tr className="ligth ligth-data">
              <th>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" />
                  <label className="form-check-label"></label>
                </div>
              </th>
              {selectedColumns.jiraTicketId && (
                <th>{translate("Ticket ID")}</th>
              )}
              {selectedColumns.creation_date && (
                <th>{translate("Creation Date")}</th>
              )}
              {selectedColumns.summary && <th>{translate("Title")}</th>}
              {/* {selectedColumns.issueType && <th>{translate('Issue Type')}</th>} */}
              {/* {selectedColumns.id_user && <th>{translate('User')}</th>} */}
              {selectedColumns.agent && <th>{translate("Agent")}</th>}
              {selectedColumns.category && <th>{translate("Category")}</th>}
              {selectedColumns.sub_category && (
                <th>{translate("Sub category")}</th>
              )}
              {selectedColumns.status && <th>{translate("Status")}</th>}
              {selectedColumns.criticality && (
                <th>{translate("Criticality")}</th>
              )}

              {<th>{translate("Actions")}</th>}
            </tr>
          </thead>
          <tbody key="#" className="ligth-body">
            {list_Ticket.map((ticket) => (
              <tr key={ticket.id_ticket}>
                <td>
                  <div className="form-check form-check-inline">
                    <input type="checkbox" className="form-check-input" />
                  </div>
                </td>

                {selectedColumns.jiraTicketId && <td>{ticket.jiraTicketId}</td>}
                {selectedColumns.creation_date && (
                  <td>{formatToTimestamp(ticket.creation_date)}</td>
                )}
                {selectedColumns.summary && <td>{ticket.summary}</td>}
                {/* {selectedColumns.issueType && <td>{ticket.issueType}</td>} */}
                {/* {selectedColumns.id_user && <td>{ticket.id_user}</td>} */}
                {selectedColumns.status && <td>{ticket.agent}</td>}
                {selectedColumns.status && <td>{ticket.category}</td>}
                {selectedColumns.status && <td>{ticket.sub_category}</td>}
                {selectedColumns.status && <td>{ticket.status}</td>}
                {selectedColumns.status && <td>{ticket.criticality}</td>}

                <td>
                  <div className="d-flex align-items-center list-action">
                    <Link
                      to={`#`}
                      className="badge badge-success mr-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Détail"
                    >
                      <i
                        className="fa fa-eye"
                        style={{ fontSize: "1.2em" }}
                      ></i>
                    </Link>
                    <a
                      className="badge bg-warning mr-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Delete"
                      data-original-title="Delete"
                    >
                      <i
                        className="ri-delete-bin-line mr-0"
                        style={{ fontSize: "1.2em" }}
                      ></i>
                    </a>
                    <a
                      className="badge bg-primary mr-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="download"
                      data-original-title="download"
                    >
                      <i
                        className="las la-download"
                        style={{ fontSize: "1.2em" }}
                      ></i>
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <span>
            {translate("Displaying")} {list_Ticket.length} {translate("out of")}{" "}
            {total}
          </span>
        </div>
        <div className="col-md-6">
          <ReactPaginate
            previousLabel={translate("previous")}
            nextLabel={translate("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
      <CreateTicketModal
        show={showCreateTicketModal}
        onHide={handleCloseCreateTicketModal}
        refreshTickets={refreshTickets}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtrage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 col-12">
              <label htmlFor="technician">Agent:</label>
              <select className="form-control" name="technician" id="technician" onChange={handleSubmit}>
                <option value="%">{translate("None")}</option>
                <option value="86">i.</option>
                <option value="96">.</option>
                <option value="16">z. belgacem</option>
                <option value="24">A. Chebab</option>
                <option value="183">. D.djilali</option>
                <option value="14">I. Fares</option>
                <option value="1">I. GEOLOCALISATION</option>
                <option value="192">m. goslove</option>
                <option value="3912">i. idenet</option>
                <option value="12">y. maamri</option>
                <option value="29">D. Maamri</option>
                <option value="182">. sadi el houcine</option>
                <option value="11">s. touati</option>
              </select>
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="category">Category:</label>
              <select className="form-control" name="category" id="category" onChange={handleSubmit}>
                <option value="%">{translate("None")}</option>
                <option value="3">Commande (Ajout de boîtier)</option>
                <option value="4">Intervention (Montage & Démontage)</option>
                <option value="5">Matériel (Problème de Boîtier)</option>
                <option value="6">Solution (Plateforme)</option>
              </select>
            </div> 
            <div className="col-md-6 col-12">
              <label htmlFor="subcat">Sub Category:</label>
              <select className="form-control" name="subcat" id="subcat" onChange={handleSubmit}>
                <option value="%">{translate("None")}</option>
                <option value="7">Cause autre</option>
                <option value="5">Cause véhicule accidenté</option>
                <option value="6">cause véhicule vendu</option>
              </select>
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="state">State:</label>
              <select className="form-control" name="state" id="state" onChange={handleSubmit}>
                <option value="">{translate("None")}</option>
                <option value="1">Attente PEC</option>
                <option value="6">Attente Retour</option>
                <option value="2">En cours</option>
                <option value="5">Non attribué</option>
                <option value="4">Rejeté</option>
                <option value="3">Résolu</option>
              </select>
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="priority">Priority:</label>
              <select className="form-control" name="priority" id="priority" onChange={handleSubmit}>
                <option value="">{translate("None")}</option>
                <option value="0">Urgent</option>
                <option value="1">Très haute</option>
                <option value="2">Haute</option>
                <option value="3">Moyenne</option>
                <option value="4">Basse</option>
                <option value="5">Très basse</option>
              </select>
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="criticality">Criticality:</label>
              <select className="form-control" name="criticality" id="criticality" onChange={handleSubmit}>
                <option value="">{translate("None")}</option>
                <option value="1">Critique</option>
                <option value="2">Grave</option>
                <option value="3">Moyenne</option>
                <option value="4">Basse</option>
              </select>
            </div>
          </div> 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}
